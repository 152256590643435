import "./Facilities.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-regular-svg-icons";
import { useDictionary } from "OdevFetch";
import { translate } from "helpers/translate";

const Facilities = ({ facilities }) => {
  const { payload, loading } = useDictionary({ query: { type: "facilities" } });

  if (loading) return <div>LOADING</div>;

  const dictionary = payload.data;

  return (
    <div className='facilities-module'>
      <h5>Udogodnienia</h5>
      <div className='facilities-wrapper'>
        {facilities.map(key => (
          <div key={key} className='facilities-wrapper-single'>
            <FontAwesomeIcon icon={faCircleCheck} />
            <p>{translate(dictionary, key)}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Facilities;
