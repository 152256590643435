import { useContext } from "react";
import { FormContext } from "../providers/FormProvider";

export const useForm = () => {
  const context = useContext(FormContext);

  if (context === undefined) {
    throw new Error("useForm was used outside of its Provider");
  }

  return { ...context };
};
