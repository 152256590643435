import "./style.scss";
import Ad from "modules/Ad/Ad";
import ContactForm from "./ContactForm/ContactForm";
import ContactCustomerService from "./ContactCustomerService/ContactCustomerService";

const Contact = () => {
  return (
    <div className='contact-page'>
      <div className='contact-wrapper'>
        <div className='contact-header'>
          <h1>Skontaktuj się z nami!</h1>
          <p>
            Jeśli masz pytania lub chcesz dowiedzieć się więcej, skontaktuj się z nami.
          </p>
        </div>
        <ContactForm />
        <Ad />
        <ContactCustomerService/>
      </div>
    </div>
  );
};

export default Contact;
