import { useLodgings } from "OdevFetch";
import { useUser } from "hooks";
import SingleObject from "./SingleObject";
import { useState } from "react";
import AddLodge from "modules/AddLodge/AddLodge";
import { useSidebar } from "hooks/useSidebar";
import { useEffect } from "react";

const ObjectsList = () => {
  const { userData } = useUser();
  const { payload, loading, refetch } = useLodgings({ isSecure: true });
  const { setSidebar } = useSidebar();

  useEffect(() => {
    setSidebar({});
  }, []);

  const [openModal, setOpenModal] = useState(false);

  const closeAction = () => {
    setOpenModal(false);
    refetch();
  };

  if (loading) return <div>Loading</div>;

  const isLodges = payload?.data.length > 0;

  return (
    <div className='col-12 room-offers'>
      <div className='room-offers-container'>
        <div className='col-12 rooms-header'>
          <p>Twoje obiekty</p>
          <button className='primary' onClick={() => setOpenModal(true)}>
            Dodaj obiekt
          </button>
        </div>
        <div className='col-12 rom-offers-add-container'>
          <div className='row m-0'>
            <div className='container'>
              {isLodges ? (
                payload?.data?.map((item, index) => (
                  <SingleObject key={index} data={item} userDataId={userData.id} />
                ))
              ) : (
                <div className='col-12 no-rooms'>Nie masz jeszcze żadnych obiektów</div>
              )}
            </div>
          </div>
        </div>
      </div>
      {openModal && <AddLodge closeAction={closeAction} />}
    </div>
  );
};

export default ObjectsList;
