import { VALIDATION_SCHEMA } from "modules/Form/validationSchema";

export const formConfig = [
  {
    id: "id",
    default: undefined,
  },
  {
    id: "start_date",
    default: "",
    validation: [...VALIDATION_SCHEMA.date],
  },
  {
    id: "finish_date",
    default: "",
    validation: [...VALIDATION_SCHEMA.date],
  },
  {
    id: "adult",
    default: "",
    validation: [...VALIDATION_SCHEMA.askValues],
  },
  {
    id: "child",
    default: "",
    validation: [...VALIDATION_SCHEMA.askValues],
  },
  {
    id: "room",
    default: "",
    validation: [...VALIDATION_SCHEMA.askValues],
  },
  {
    id: "message",
    default: "",
    validation: [...VALIDATION_SCHEMA.message],
  },
  {
    id: "name",
    default: "",
    validation: [...VALIDATION_SCHEMA.name],
  },
  {
    id: "phone",
    default: "",
    validation: [...VALIDATION_SCHEMA.phone],
  },
  {
    id: "email",
    default: "",
    validation: [...VALIDATION_SCHEMA.email],
  },
];
