import { useRooms } from "OdevFetch";
import Modal from "modules/Modal/Modal";
import { Input, Checkbox, ActionButton } from "modules/Form";
import FormProvider from "providers/FormProvider";

import { formConfig } from "./config";
import "./AddEditRoom.scss";
import { useParams } from "react-router-dom";

const AddEditRoom = ({ id, closeAction }) => {
  const isEdit = !!id;

  const { id: lodge_id } = useParams();
  const { payload, loading, save } = useRooms({ id, isLazy: !isEdit, isSecure: true });

  const title = isEdit ? "Edytuj pokój" : "Dodaj pokój";

  if (isEdit && loading) return <div>Loading</div>;

  const submitHandler = async data => {
    await save({ body: { ...data, lodge_id } });
    closeAction();
  };

  return (
    <Modal
      title={title}
      className='form add-room-module'
      closeAction={() => {
        closeAction();
      }}
    >
      <FormProvider payload={payload?.[0] || []} config={formConfig}>
        <div className='container'>
          <div className='row'>
            <div className='col-12 col-md-6'>
              <Input title='Rodzaj' id='type' className='price' />
            </div>
            <div className='col-12 col-md-6'>
              <Input
                title='Ile osób'
                id='people_count'
                type='number'
                className='price'
              />
            </div>
          </div>
          <div className='row'>
            <div className='col-12 col-md-6'>
              <Input
                title='Cena za dobę (1noc)'
                id='price_day'
                className='price'
                type='number'
              />
            </div>
            <div className='col-12 col-md-6'>
              <Input
                title='Cena za osobę'
                id='price_person'
                className='price'
                type='number'
              />
            </div>
          </div>
          <div className='row'>
            <div className='col-12 col-md-6'>
              <Checkbox
                id='is_weekend_price'
                className='price'
                title='Cena za weekend (1 noc)'
              />
            </div>
          </div>
          <div className='row'>
            <div className='col-12 col-md-6'>
              <Input
                title='Cena za dobę (1noc)'
                id='price_day_weekend'
                onDisabled={data => !data.is_weekend_price}
                className='price'
                type='number'
              />
            </div>
            <div className='col-12 col-md-6'>
              <Input
                title='Cena za osobę'
                id='price_person_weekend'
                onDisabled={data => !data.is_weekend_price}
                className='price'
                type='number'
              />
            </div>
          </div>
          <div className='row line-with-buttons'>
            <div className='col-12 col-md-6 '>
              <Input title='Liczba pokoi' id='amount' className='room' type='number' />
            </div>
            <div className='col-6 button-group'>
              <ActionButton
                type='add'
                confirmMessage='Pokój zostanie zapisany'
                onClick={data => submitHandler(data)}
                className="primary"
              >
                Zapisz
              </ActionButton>
              <ActionButton type='back' onClick={() => closeAction()} className="secondary">
                Anuluj
              </ActionButton>
            </div>
          </div>
        </div>
      </FormProvider>
    </Modal>
  );
};

export default AddEditRoom;
