import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./ForgotPassword.scss";
import { useUsers } from "OdevFetch";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const { resetPassword } = useUsers({ isLazy: true });

  const handleSubmit = async e => {
    e.preventDefault();
    await resetPassword({ email });
    navigate("/login");
  };

  return (
    <div className='forgot-password-module'>
      <div className='forgot-password-container'>
        <h1 className='forgot-password-title'>Zapomniałeś hasła?</h1>
        <form onSubmit={handleSubmit}>
          <div className='form-group'>
            <label htmlFor='email'>Podaj swój e-mail</label>
            <input
              type='email'
              id='email'
              value={email}
              onChange={e => setEmail(e.target.value)}
              required
            />
          </div>
          <div className='form-buttons'>
            <button className='primary'>Resetuj hasło</button>
            <Link to='/login' className='back-to-login-link'>
              Wróć do logowania
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ForgotPassword;
