import React from "react";
import OwlCarousel from "react-owl-carousel2";
import "react-owl-carousel2/src/owl.carousel.css";
import "./CategoryBlocks.scss";
import { useLodgeContext } from "hooks";
import { useNavigate } from "react-router-dom";

const CategoryBlocks = () => {
  const navigate = useNavigate();

  const { setFilters, filtersData } = useLodgeContext();

  const handleClick = id => {
    setFilters({ ...filtersData, type: id });
    navigate("/noclegi");
  };

  const options = {
    responsive: {
      0: {
        items: 2,
      },
      768: {
        items: 3,
      },
      992: {
        items: 5,
      },
    },
    nav: true,
    rewind: true,
    autoplay: true,
    navText: ["<", ">"],
  };

  const tableData = [
    { id: 1, title: "Hotel", name: "hotel" },
    { id: 2, title: "Apartament", name: "apartament" },
    { id: 3, title: "Pokój", name: "pokoj" },
    { id: 4, title: "Dom", name: "dom" },
    { id: 5, title: "Pensjonat", name: "pensjonat" },
    { id: 6, title: "Kemping", name: "kemping" },
    { id: 7, title: "Ośrodek wczasowy", name: "osrodek" },
  ];

  const positions = tableData.map(item => (
    <div class='category-item' onClick={() => handleClick(item.id)}>
      <img src={`/categoryImages/${item.name}.png`} alt={item.name} />
      <h3>{item.title}</h3>
    </div>
  ));

  return (
    <div className='category-blocks'>
      <h2>Kategorie</h2>
      <OwlCarousel options={options}>{positions}</OwlCarousel>
    </div>
  );
};

export default CategoryBlocks;
