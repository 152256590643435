import React from "react";
import ReactDOM from "react-dom";

import "./Modal.scss";
import Card from "../Card/Card";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

const modalRoot = document.getElementById("modal-root");

class Modal extends React.Component {
  constructor(props) {
    super(props);
    this.el = document.createElement("div");
    this.state = {
      active: false,
    };
  }

  closeHandler(event) {
    if (event.currentTarget === event.target) {
      this.props.closeAction();
    }
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ active: true });
    }, 200);
    modalRoot.appendChild(this.el);
  }

  componentWillUnmount() {
    modalRoot.removeChild(this.el);
  }

  getContent() {
    const { children, size, closeAction, className, title } = this.props;
    return (
      <div
        className={`modal ${className} ${size} ${this.state.active && "active"}`}
        onClick={e => this.closeHandler(e)}
      >
        <Card>
          {title &&<h2 className='title'>{title}</h2>}
            <FontAwesomeIcon
            icon={faTimes}
            className='close-icon'
            onClick={() => closeAction()}
            />
          {children}
        </Card>
      </div>
    );
  }

  render() {
    return ReactDOM.createPortal(this.getContent(), this.el);
  }
}

export default Modal;
