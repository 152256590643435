import "./CalendarBody.scss";
import moment from "moment";
import { useState } from "react";
import { useCalendar, useRooms } from "OdevFetch";
import { useParams } from "react-router-dom";
import CalendarRow from "./CalendarRow";
import Loading from "modules/Loading/Loading";
import CalendarPopup from "modules/CalendarPopup/CalendarPopup";

const basicCalendarModalData = {
  start_date: moment().format("yyyy-MM-DD"),
  finish_date: "",
  reservation_name: "",
  type: null,
};

const CalendarBody = ({ size, firstDate }) => {
  const { id: lodge_id } = useParams();
  const { payload: roomsPayload, rooms: roomsLoading } = useRooms({
    query: { lodge_id },
    isSecure: true,
  });
  const {
    payload: calendarPayload,
    refetch,
    loading: calendarLoading,
  } = useCalendar({
    id: lodge_id,
    isSecure: true,
  });

  const [modalState, setModalState] = useState({
    isOpen: false,
    data: { basicCalendarModalData },
  });

  const addReservation = ({ room, startDate }) => {
    setModalState({
      isOpen: true,
      data: {
        ...basicCalendarModalData,
        lodgeId: lodge_id,
        roomId: room.id,
        roomIndex: room.index,
        start_date: startDate,
        end_date: moment(startDate).add(1, "days").format(),
      },
    });
  };

  const editReservation = reservation => {
    setModalState({
      isOpen: true,
      data: {
        ...reservation,
      },
    });
  };

  const closeAction = () => {
    setModalState({
      isOpen: false,
      date: {},
    });
    refetch();
  };

  const getDaysHeader = () => {
    const startDate = moment(firstDate);
    const days = [];
    for (let i = 0; i < size; i++) {
      days.push(
        <div key={i} className='header-cell cell'>
          {startDate.clone().add(i, "days").format("dd")}
        </div>
      );
    }
    return days;
  };

  const getReservationsByRoom = ({ room }) => {
    const data = calendarPayload.data.filter(
      item => item.roomId === room.id && item.roomIndex === room.index
    );
    return data;
  };

  const getCalendarRows = rooms => {
    let columnsRooms = [];

    rooms.forEach(room => {
      for (let i = 0; i < room.amount; i++) {
        columnsRooms.push({
          name: `${room.type} (${i + 1})`,
          index: i + 1,
          id: room.id,
        });
      }
    });

    return (
      <div className='calendar-items'>
        {columnsRooms.map((room, index) => (
          <CalendarRow
            onAddReservation={data => addReservation(data)}
            reservations={getReservationsByRoom({ room })}
            onEditReservation={editReservation}
            firstDate={firstDate}
            size={size}
            room={room}
            key={index}
          />
        ))}
      </div>
    );
  };

  if (calendarLoading || roomsLoading) {
    return <Loading />;
  }

  return (
    <>
      <div className='calendar-body'>
        <div className='column-header'>{getDaysHeader()}</div>
        {getCalendarRows(roomsPayload?.data || [])}
      </div>
      {modalState.isOpen && (
        <CalendarPopup data={modalState.data} closeAction={closeAction} />
      )}
    </>
  );
};

export default CalendarBody;
