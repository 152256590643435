import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./RoomTitle.scss";
import { faHeart } from "@fortawesome/free-regular-svg-icons";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";

const RoomTitle = ({ data }) => {
  const { rating, address, city, name, phone, id } = data;

  const [favorites, setFavorites] = useState([]);

  useEffect(() => {
    const existingFavorites = JSON.parse(localStorage.getItem("favorites")) || [];
    setFavorites(existingFavorites);
  }, []);

  const favoriteHandler = e => {
    e.preventDefault();
    const existingFavorites = JSON.parse(localStorage.getItem("favorites")) || [];

    if (!existingFavorites.includes(id)) {
      existingFavorites.push(id);
      localStorage.setItem("favorites", JSON.stringify(existingFavorites));
      setFavorites(existingFavorites);
    } else {
      const index = existingFavorites.filter(element => element !== id);
      localStorage.setItem("favorites", JSON.stringify(index));
      setFavorites(index);
    }
  };

  const formatPhoneNumber = phoneNumber => {
    if (!phoneNumber) return "";
    const regex = /\d{1,3}/g;
    const parts = phoneNumber.toString().match(regex);
    return parts.join(" ");
  };

  return (
    <div className='room-title-module'>
      <div className='row title-adress-section'>
        <div className='col-12 '>
          <h3>{name}</h3>
        </div>
        <div className='col-12'>
          <span className='adress'>
            <FontAwesomeIcon icon={faLocationDot} />
            <span>
              {address}, {city}
            </span>
          </span>
        </div>
      </div>
      <div className='information-title-section'>
        <div className='information-title-section-child'>
          <span className='phone-title'>{formatPhoneNumber(phone)}</span>
        </div>
        <div className='information-title-section-child heart-icon'>
          <FontAwesomeIcon
            icon={faHeart}
            className={favorites.includes(id) ? "favorite-icon-red" : "favorite-icon-grey"}
            onClick={favoriteHandler}
          />
        </div>
        <div className='information-title-section-child'>
          <h6 className='rating'>
            Ocena <span>{rating}</span>
          </h6>
        </div>
      </div>
    </div>
  );
};

export default RoomTitle;
