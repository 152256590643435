import React, { useState, useEffect } from "react";
import "./CookieConsent.scss";

const CookieConsent = () => {
  const [accepted, setAccepted] = useState(false);

  useEffect(() => {
    const isAccepted = localStorage.getItem("cookieConsent");
    if (isAccepted) {
      setAccepted(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem("cookieConsent", true);
    setAccepted(true);
  };

  return (
    <div className='cookie-consent'>
      {!accepted && (
        <div className='cookie-consent-message'>
          <h3>Polityka plików cookie</h3>
          <p>
            Ta strona internetowa używa plików cookie, aby zapewnić najlepsze
            doświadczenie użytkownika. Pliki cookie są małymi plikami tekstowymi
            przechowywanymi na Twoim urządzeniu, które pomagają nam analizować ruch na
            stronie oraz dostosowywać reklamy. Korzystając z naszej strony, zgadzasz się
            na używanie plików cookie.
          </p>
          <div className='cookie-consent-buttons'>
            <button onClick={handleAccept}>Akceptuję</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default CookieConsent;
