export const askForLodgeConfig = [
  {
    id: "email",
    default: "",
  },
  {
    id: "start_date",
    default: "",
  },
  {
    id: "finish_date",
    default: "",
  },
  {
    id: "adult",
    default: "",
  },
  {
    id: "child",
    default: "",
  },
];
