import "./Ad.scss";
import Image from "modules/Image";

const Ad = () => {
  return (
    <div className='ad-module'>
      <div className=' ad-info col-12'>
        <Image src={process.env.REACT_APP_PUBLIC_URL + "/reklama.jpg"} />
      </div>
    </div>
  );
};

export default Ad;
