import AskForSleep from "./AskForSleep/AskForSleep";
import { Route, Routes } from "react-router-dom";

import Calendar from "./Calendar/Calendar";
import DescriptionItem from "./DescriptionItem/DescriptionItem";
import Facilities from "./Facilities/Facilities";
import Gallery from "./Gallery/Gallery";
import Map from "./Map/Map";
import Opinions from "./Opinions/Opinions";
import Pricing from "./Pricing/Pricing";
import Ad from "../../../modules/Ad/Ad";
import "./RoomDescription.scss";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { roomLinksConfig } from "./config";

const RoomDescription = ({ data, refetch }) => {
  const { id } = useParams();
  const formatPhoneNumber = phoneNumber => {
    const regex = /\d{1,3}/g;
    const parts = phoneNumber.toString().match(regex);
    return parts.join(" ");
  };

  const {
    phone,
    description,
    address,
    facilities,
    city,
    gallery,
    reviews,
    postal_code,
  } = data;

  return (
    <div className='row room-description-module'>
      <div className='col-12 description-links'>
        {roomLinksConfig.map((item, index) => (
          <Link key={index} to={`/nocleg/${id}/${item.path}`}>
            {item.title}
          </Link>
        ))}
      </div>
      <div className='col-12 col-lg-8'>
        <div className='row descriptions'>
          <div className='col-12 gallery-box'>
            <Routes>
              <Route
                exact
                path='/opis'
                element={
                  <DescriptionItem
                    description={description}
                    address={address}
                    postal_code={postal_code}
                    city={city}
                  />
                }
              />
              <Route
                path='/galeria'
                element={<Gallery isLogged={false} gallery={gallery || []} />}
              />
              <Route
                path='/opinie'
                element={<Opinions reviews={reviews} refetch={refetch} />}
              />
              <Route path='/mapa' element={<Map location={{ city, address }} />} />
              <Route path='/cennik' element={<Pricing data={data} />} />
              <Route path='/kalendarz' element={<Calendar />} />
              <Route
                path='/udogodnienia'
                element={<Facilities facilities={facilities} />}
              />
            </Routes>
          </div>
        </div>
      </div>
      <div className='col-12 col-lg-4 '>
        <div className='row'>
          <div className='col-12'>
            <AskForSleep data={data} />
          </div>
          <div className='col-12'>
            <div className='phone'>
              <div className='phone-description'>
                <span>{formatPhoneNumber(phone)}</span>
                <span>(Powołaj się na Rentali)</span>
              </div>
            </div>
          </div>
          <div className='col-12'>
            <div className='room-description-ad'>
              <Ad />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RoomDescription;
