import "./Footer.scss";
import { footerLinks, footerSocialMedia } from "./config";
import { Container } from "react-bootstrap";
import Image from "modules/Image";

const Footer = () => {
  return (
    <div className='footer'>
      <div className='fundusze-ikony'>
        <Container>
          <img className='logos' src='/sponsors_logos.png' />
          <img className='logos-min' src='/sponsors_logos_min.png' />
          <h5>
            „Europejski Fundusz Rolny na rzecz Rozwoju Obszarów Wiejskich: Europa
            inwestująca w obszary wiejskie”
          </h5>
          <h6>
            Operacja pn. „Podjęcie działalności gospodarczej w celu stworzenia
            turystycznego portalu internetowego i zakup środków trwałych przeznaczonych
            do świadczenia usług marketingu internetowego.” współfinansowana ze środków
            Unii Europejskiej w ramach poddziałania 19.2 Wsparcie na wdrażanie operacji
            w ramach strategii rozwoju lokalnego kierowanego przez społeczność,
            działanie „Wsparcie dla rozwoju lokalnego w ramach inicjatywy LEADER”
            Programu Rozwoju Obszarów Wiejskich na lata 2014-2020. <br />
            <br />
            Projekt dofinansowany za pośrednictwem Lokalnej Grupy Działania „Razem dla
            Powiatu Radziejowskiego”.
          </h6>
        </Container>
      </div>
      <Container>
        <div className='footer-wrapper'>
          <div className='footer-content row'>
            <div className='footer-content-links col-12 col-md-4 col-lg-3 order-md-1'>
              <h2>
                <a href='#'>rentali.pl</a>
              </h2>
              <ul>
                {footerLinks.map(item => (
                  <li key={`footer-links-${item.id}`}>{item.value}</li>
                ))}
              </ul>
            </div>

            <div className='footer-content-social-media col-12 col-md-4 col-lg-3 order-md-3'>
              <h2>Obserwuj nas</h2>
              <ul>
                {footerSocialMedia.map(item => (
                  <li key={`footer-social-${item.id}`}>
                    <a href={item.href} target='_blank' rel='noreferrer'>
                      {item.icon}
                    </a>
                  </li>
                ))}
              </ul>
            </div>

            <div className='footer-copyright col-12 col-md-4 order-md-2 col-lg-6'>
              <p>
                <br /> Korzystanie z serwisu wiąże się z akceptacją{" "}
                <a href='/Regulamin_rentali.pl.pdf' target='_blank' rel='noreferrer'>
                  regulaminów
                </a>{" "}
                oraz{" "}
                <a
                  href='/Polityka_Prywatnosci_rentali.pl.pdf'
                  target='_blank'
                  rel='noreferrer'
                >
                  polityki prywatności
                </a>
                .<br />
                Do prawidłowego funkcjonowania serwisu używamy ciasteczek.
              </p>
              <h5>
                <a href='https://1v0.pl' target='_blank' rel='noreferrer'>
                  Powered by 1v0
                </a>
              </h5>
            </div>

            {/* <div className='footer-content-image'>
              <img src='/urzad-marszalkowski.png' alt='urzad-marszalkowski'></img>
            </div> */}
          </div>
        </div>
      </Container>

      {/* <Container>
        <div className='footer-wrapper'>
          <div className='footer-copyright'>
            <p>
              Firma 1v0, właściciel www.rentali.pl nie odpowiada za treści ogłoszeń
              przekazanych przez właścicieli obiektów.
              <br /> Korzystanie z serwisu wiąże się z akceptacją{" "}
              <a href='/Regulamin_rentali.pl.pdf' target='_blank' rel='noreferrer'>
                regulaminów
              </a>{" "}
              oraz{" "}
              <a
                href='/Polityka_Prywatnosci_rentali.pl.pdf'
                target='_blank'
                rel='noreferrer'
              >
                polityki prywatności
              </a>
              .<br />
              Do prawidłowego funkcjonowania serwisu używamy ciasteczek.
            </p>
            <h5>
              <a href='https://1v0.pl' target='_blank' rel='noreferrer'>
                Powered by 1v0
              </a>
            </h5>
          </div>
        </div>
      </Container> */}
    </div>
  );
};

export default Footer;
