import { VALIDATION_SCHEMA } from "modules/Form/validationSchema";

export const formConfig = [
  {
    id: "id",
    default: undefined,
  },
  {
    id: "name",
    default: "",
    validation: [...VALIDATION_SCHEMA.name],
  },
  {
    id: "address",
    default: "",
    validation: [...VALIDATION_SCHEMA.address],
  },
  {
    id: "postal_code",
    default: "",
    validation: [...VALIDATION_SCHEMA.postCode],
  },
  {
    id: "min_price",
    default: "",
    validation: [...VALIDATION_SCHEMA.price],
  },
  {
    id: "phone",
    default: "",
    validation: [...VALIDATION_SCHEMA.phone],
  },
  {
    id: "description",
    default: "",
    validation: [...VALIDATION_SCHEMA.description],
  },
  {
    id: "city",
    default: "",
    validation: [...VALIDATION_SCHEMA.city],
  },
  {
    id: "facilities",
    default: "",
    validation: [...VALIDATION_SCHEMA.facilities],
  },
  {
    id: "type",
    default: "",
    validation: [...VALIDATION_SCHEMA.objectType],
  },
];
