import "./Lodge.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import GalleryPreview from "modules/GalleryPreview/GalleryPreview";
import Image from "modules/Image";

const Lodge = ({ data }) => {
  const { address, city, description, name, min_price, reviews, id, gallery } = data;
  const navigate = useNavigate();
  const goToLodge = () => {
    navigate(`/nocleg/${id}/opis`);
  };

  const [favorites, setFavorites] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [index, setIndex] = useState(0);

  const modalHandler = (modalState, photoIndex) => {
    setOpenModal(modalState);
    setIndex(photoIndex);
  };

  const closeAction = () => {
    setOpenModal(false);
  };

  useEffect(() => {
    const existingFavorites = JSON.parse(localStorage.getItem("favorites")) || [];
    setFavorites(existingFavorites);
  }, []);

  const favoriteHandler = e => {
    e.preventDefault();
    const existingFavorites = JSON.parse(localStorage.getItem("favorites")) || [];

    if (!existingFavorites.includes(id)) {
      existingFavorites.push(id);
      localStorage.setItem("favorites", JSON.stringify(existingFavorites));
      setFavorites(existingFavorites);
    } else {
      const index = existingFavorites.filter(element => element !== id);
      localStorage.setItem("favorites", JSON.stringify(index));
      setFavorites(index);
    }
  };

  const mappedGallery = gallery.map(image => ({
    image_name: image.image_name,
    id: image.id,
  }));

  const images = {
    primary: gallery.find(item => item?.is_primary),
    rest: gallery?.filter(item => !item?.is_primary)?.slice(0, 3) || [],
  };

  const rating = reviews?.length
    ? (
        reviews.reduce((sum, review) => sum + review.rating, 0) / reviews.length
      ).toFixed(2)
    : "Brak";

  return (
    <div className='lodge-module'>
      <div className='lodge-image' onClick={() => goToLodge()}>
        <Image
          alt='Zdjęcie obiektu'
          src={`${process.env.REACT_APP_IMAGES_PATH}${id}/${images.primary?.image_name}`}
        />
        <div
          className='favorite-icon'
          onClick={e => {
            e.stopPropagation();
            favoriteHandler(e);
          }}
        >
          <a
            href='/'
            className={favorites.includes(id) ? "icon-color-red" : "icon-color-grey"}
          >
            <FontAwesomeIcon icon={faHeart} />
          </a>
        </div>
      </div>
      <div className='lodge-content'>
        <div className='lodge-content-header'>
          <div className='lodge-title'>
            <h4>{name}</h4>
            <h5>
              {address} {city}
            </h5>
          </div>
          <div className='lodge-rating'>
            <h5>ocena</h5>
            <h4>{rating}</h4>
          </div>
        </div>
        <div className='lodge-content-description'>
          <p>{description}</p>
        </div>
        <div className='lodge-content-bottom'>
          <div className='mini-images-container'>
            {images.rest.map((image, index) => (
              <div
                className='mini-image'
                key={image.id}
                onClick={() => modalHandler(true, index + 1)}
              >
                <Image
                  className='img-fluid'
                  alt='apartment'
                  src={`${process.env.REACT_APP_IMAGES_PATH}${id}/${image.image_name}`}
                />
              </div>
            ))}
          </div>
          <div className='lodge-summary'>
            <p>Cena od {min_price}zł</p>
            <button className='primary' onClick={() => goToLodge()}>
              Zobacz nocleg
            </button>
          </div>
        </div>
      </div>
      {openModal && (
        <GalleryPreview
          index={index}
          images={mappedGallery}
          closeAction={closeAction}
          lodgeId={id}
        />
      )}
    </div>
  );
};

export default Lodge;
