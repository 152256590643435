export const VALIDATION_SCHEMA = {
  name: [
    {
      test: value => value?.length < 3,
      message: "Nazwa jest za krótka",
    },
    {
      test: value => value?.length > 50,
      message: "Nazwa jest za długa",
    },
  ],
  phone: [
    {
      test: value => !regex.phone.test(value),
      message: "wpisz numer w formacie: 48111222333",
    },
  ],
  price: [
    {
      test: value => !value,
      message: "Proszę wpisać cenę noclegu w PLN",
    },
    {
      test: value => !regex.numbers.test(value),
      message: "Proszę wpisać tylko cyfry",
    },
  ],
  postCode: [
    {
      test: value => !regex.postCode.test(value),
      message: "Kod pocztowy jest niepoprawny",
    },
  ],
  nip: [
    {
      test: value => Number(value) < 999999999 && Number(value) > 10000000000,
      message: "NIP musi zawierać 10 cyfr",
    },
  ],
  description: [
    {
      test: value => !value,
      message: "Opis nie może być pusty",
    },
    {
      test: value => value?.length > 5000,
      message: "Opis jest za długi",
    },
    {
      test: value => value?.length < 10,
      message: "Opis jest za krótki",
    },
  ],
  address: [
    {
      test: value => !value,
      message: "Adres nie może być pusty",
    },
    {
      test: value => value?.length < 3,
      message: "Adres jest za krótki",
    },
    {
      test: value => value?.length > 100,
      message: "Adres jest za długie",
    },
  ],
  city: [
    {
      test: value => !value,
      message: "Miasto nie może być puste",
    },
    {
      test: value => value?.length < 3,
      message: "Miasto jest za krótkie",
    },
    {
      test: value => value?.length > 100,
      message: "Miasto jest za długie",
    },
  ],
  country: [
    {
      test: value => !value,
      message: "Kraj nie może być pusty",
    },
    {
      test: value => value?.length < 3,
      message: "Kraj jest za krótki",
    },
    {
      test: value => value?.length > 100,
      message: "Kraj jest za długi",
    },
  ],
  comment: [
    {
      test: value => value?.length < 3,
      message: "Komentarz jest za krótki",
    },
    {
      test: value => value?.length > 150,
      message: "Komentarz jest za długi",
    },
  ],
  email: [
    {
      test: value => !regex.email.test(value),
      message: "Proszę wpisać poprawny email",
    },
  ],
  message: [
    {
      test: value => value?.length < 10,
      message: "Wiadomość musi zawierać przynajmniej 10 znaków",
    },
    {
      test: value => value?.length > 300,
      message: "Wiadomość może zawierać maksymalnie 300 znaków",
    },
  ],
  askValues: [
    {
      test: value => !value,
      message: "Pole nie może być puste",
    },
    {
      test: value => value < 0,
      message: "Nieprawidłowe dane",
    },
  ],
  objectType: [
    {
      test: value => value === "(pusty)" || !value,
      message: "Wybierz rodzaj obiektu",
    },
  ],
  facilities: [
    {
      test: value => !value,
      message: "Wybierz udogodnienia",
    },
  ],
  date: [
    {
      test: value => !value,
      message: "Wybierz datę",
    },
  ],
  password: [
    {
      test: value => !value,
      message: "Hasło musi zawierać przynajmniej 6 znaków",
    },
  ],
  roomValues: [
    {
      test: value => !value,
      message: "Pole nie może być puste",
    },
    {
      test: value => value < 1,
      message: "Wartość musi być dodatnia",
    },
  ],
};

const regex = {
  phone: /^\d{11}$/,
  email: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
  znaki: /^[A-Za-z0-9 ]+$/,
  pesel: /^[0-9]{11}$/,
  postCode: /^[0-9]{2}-[0-9]{3}/,
  numbers: /^[0-9]*$/,
};
