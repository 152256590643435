import { useState } from "react";
import "./SmartGallery.scss";
import Image from "modules/Image";
import GalleryPreview from "modules/GalleryPreview/GalleryPreview";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faCrown } from "@fortawesome/free-solid-svg-icons";

const SmartGallery = ({ images, onRemove, onSelectPrimary }) => {
  const [openModal, setOpenModal] = useState(false);
  const [index, setIndex] = useState(0);

  const modalHandler = (modalState, photoIndex) => {
    setOpenModal(modalState);
    setIndex(photoIndex);
  }

  return (
    <div className='smart-gallery-module'>
      <div className='smart-gallery-content'>
        {images.map((image, index) => (
          <div
            key={index}
            className='image-item'
            onClick={() => modalHandler(true, index)}
          >
            <Image src={`${process.env.REACT_APP_IMAGES_PATH}${image.path}`} />
            {onRemove && (
              <div className='image-hover'>
                <div className='remove-border' onClick={(e) => onRemove(e, image)}>
                  <FontAwesomeIcon icon={faTrash} />
                </div>
                {!image.is_primary && (
                  <div className='primary-photo-border' onClick={(e) => onSelectPrimary(e, image)}>
                    <FontAwesomeIcon icon={faCrown} />
                  </div>
                )}
              </div>
            )}
          </div>
        ))}
      </div>
      {openModal && (
        <GalleryPreview
          index={index}
          images={images}
          showThumbnails={false}
          lodgeId={images[index].lodging_id}
          closeAction={() => setOpenModal(false)}
        />
      )}
    </div>
  );
};

export default SmartGallery;
