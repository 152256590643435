import { fetchSetting } from "./fetchConfig";


export const returnFetch = async ({ endpoint, body, isImagesEndpoint }) => {
  document.showLoading();
  const fullPath = `${
    isImagesEndpoint ? process.env.REACT_APP_USERS_IMAGES_PATH : process.env.REACT_APP_API_PATH
  }/${endpoint}`;

  const preparedBody = isImagesEndpoint ? body : body ? JSON.stringify(body) : "";

  const initValues = () => {
    if( !isImagesEndpoint){
      return{
        ...fetchSetting,
        headers: {
          ...fetchSetting.headers,
          ["Access-Token"]: `${sessionStorage.getItem("accessToken")}`,
        },
        body: preparedBody,
      }
    } else {
      return {
        ...fetchSetting,
        headers: {
          ["Access-Token"]: `${sessionStorage.getItem("accessToken")}`,
        },
        body: body
      }
    }
  }

  const output = await fetch(fullPath, initValues())
    .then(response => response.json())
    .then(res => {
      if (res && res.status && res.message && !res.message.includes("ERROR-LOGS: ")) {
        document.showAlert(res.status, "Sukces", res.message);
      }
      return res;
    });

  const { data, status, message } = await output;
  if (status && status === "error" && message) {
    document.showAlert(status, "Błąd", message);
  }
  document.closeLoading();
  return data;
};

export const returnAskFetch = async ({ endpoint, body }) => {
  const output = await fetch(`${process.env.REACT_APP_API_PATH}/${endpoint}`, {
    ...fetchSetting,
    headers: {
      ...fetchSetting.headers,
      ["Access-Token"]: `${sessionStorage.getItem("accessToken")}`,
    },
    body: body ? JSON.stringify(body) : "",
  })
    .then(response => response.json())
    .then(res => {
      if (res && res.status && res.message && !res.message.includes("ERROR-LOGS: ")) {
        document.showInfo(body, res.status);
      }

      return res;
    });

  const { data, status, message } = await output;
  if (status && status === "error" && message) {
    document.showInfo(data, status);
  }

  return data;
};
