import FormProvider from "providers/FormProvider";
import { Input, ActionButton, Textarea } from "modules/Form";
import { contactConfig } from "../config";
import ReCAPTCHA from "react-google-recaptcha";
import { useContactForm } from "OdevFetch";
import { useRef } from "react";

const ContactForm = () => {
  const { sendMail } = useContactForm();
  const recaptcha = useRef();

  const submitHandler = async data => {
    const captchaValue = recaptcha.current.getValue();
    if (!captchaValue) {
      document.showAlert("error", "Błąd", "Musisz potwierdzić, że nie jesteś robotem!");
    } else {
      await sendMail({ body: data });
    }
  };

  return (
    <div className='contact-form'>
      <FormProvider config={contactConfig} className='row'>
        <div className='form'>
          <h1>Formularz kontaktowy</h1>
          <div className='form-group col-12'>
            <Input
              id='name'
              type='text'
              placeholder='Imię i nazwisko'
              className='name'
            />
            <Input id='email' type='email' placeholder='Email' className='email' />
          </div>
          <div className='form-group col-12'>
            <Input id='subject' placeholder='Temat wiadomości' />
          </div>
          <div className='form-group col-12'>
            <Textarea id='text' placeholder='Treść wiadomości' type='textarea' />
          </div>
          <div className='form-buttons col-12'>
            <div className='recaptcha'>
              <ReCAPTCHA ref={recaptcha} sitekey={process.env.REACT_APP_SITE_KEY} />
            </div>
            <div className='button'>
              <ActionButton type='add' className='primary' onClick={submitHandler}>
                Wyślij
              </ActionButton>
            </div>
          </div>
        </div>
      </FormProvider>
    </div>
  );
};

export default ContactForm;
