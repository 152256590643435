import { VALIDATION_SCHEMA } from "modules/Form/validationSchema";

export const formConfig = [
  {
    id: "id",
    default: undefined,
  },
  {
    id: "type",
    default: 1,
    validation: [
      {
        test: value => value === null,
        message: "Zaznacz typ blokady",
      },
    ],
  },
  {
    id: "start_date",
    default: "",
    validation: [...VALIDATION_SCHEMA.date],
  },
  {
    id: "end_date",
    default: "",
    validation: [...VALIDATION_SCHEMA.date],
  },
  {
    id: "blockadeDescription",
    default: "",
    validation: [...VALIDATION_SCHEMA.askValues],
  },
  {
    id: "lodgeId",
    default: "",
  },
  {
    id: "roomId",
    default: "",
  },
  {
    id: "roomIndex",
    default: "",
  },
];
