import React from "react";
import { useUser } from "hooks";
import "./Account.scss";
import FormProvider from "providers/FormProvider";
import { Input, ActionButton } from "modules/Form";
import { accountConfig } from "./config.js";
import Loading from "modules/Loading/Loading";
import { useUsers } from "OdevFetch";
import { useEffect } from "react";
import { useSidebar } from "hooks/useSidebar";
import InvoiceForm from "./InvoiceForm/InvoiceForm";

const Account = () => {
  const { userData, isLoggedIn } = useUser();
  const { save } = useUsers({ isLazy: true, isSecure: true });
  const { setSidebar } = useSidebar();

  useEffect(() => {
    setSidebar({});
  }, []);

  if (Object.keys(userData).length === 0) return <Loading />;

  const submitHandler = async data => {
    const { password, password_repeat, old_password } = data;

    if (old_password) {
      if (password !== password_repeat || password.length < 6)
        return document.showAlert(
          "error",
          "Błąd",
          "Hasła muszą być identyczne oraz zawierać minimum 6 znaków!"
        );
      if (password === old_password)
        return document.showAlert(
          "error",
          "Błąd",
          "Poprzednie hasło jest takie samo jak nowe."
        );
    }

    await save({
      body: {
        id: userData?.id,
        ...data,
      },
    });

    isLoggedIn();
  };

  return (
    <div>
      <div className='account-page'>
        <FormProvider config={accountConfig} payload={userData || []}>
          {(userData?.first_name === null || userData?.last_name === null) && (
            <div className='account-nodata-alert'>
              <h1>Wypełnij dane użytkownika!</h1>
            </div>
          )}
          <div className='row'>
            <div className='col-12'>
              <h1>Dane użytkownika</h1>
              <div className='row'>
                <Input
                  title='Imię'
                  id='first_name'
                  type='name'
                  className='col-12 col-md-6'
                />
                <Input
                  title='Nazwisko'
                  id='last_name'
                  type='name'
                  className='col-12 col-md-6'
                />
                <Input
                  title='Email'
                  id='email'
                  type='email'
                  className='col-12 col-md-6'
                />
              </div>
            </div>
            <div className='col-12'>
              <h1>Zmień hasło</h1>
              <div className='row'>
                <Input
                  title='Poprzednie hasło'
                  type='password'
                  id='old_password'
                  className='col-12 col-md-6'
                />
                <Input
                  title='Nowe hasło'
                  type='password'
                  id='password'
                  className='col-12 col-md-6'
                />
                <Input
                  title='Powtórz hasło'
                  id='password_repeat'
                  type='password'
                  className='col-12 col-md-6'
                />
              </div>
            </div>
          </div>

          <div className='button'>
            <ActionButton
              type='add'
              confirmMessage='Czy jesteś pewien wprowadzonych zmian?'
              className='primary'
              onClick={data => submitHandler(data)}
            >
              Zapisz
            </ActionButton>
          </div>
        </FormProvider>
      </div>
      <InvoiceForm />
    </div>
  );
};

export default Account;
