import DatePicker, { registerLocale } from "react-datepicker";
import { useState } from "react";
import { useForm } from "hooks";
import { get } from "lodash";
import pl from "date-fns/locale/pl";
import { format } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";
import "./DateInput.scss";

const DateInput = ({ title, id, accessor }) => {
  const { onChange: onChangeCallback, data, validationMessage, isSendTry } = useForm();

  const value = accessor ? get(data, accessor) : get(data, id);
  const dateObject = value ? new Date(value) : new Date();
  const [date, setDate] = useState(dateObject);

  const onChange = date => {
    const newDate = new Date(date);
    onChangeCallback({ id, value: format(new Date(newDate), "yyyy-MM-dd") });
    setDate(date);
  };
  registerLocale("pl", pl);

  return (
    <div className={`date-input ${!onChangeCallback && "disabled"}`}>
      <label>{title}</label>
      <DatePicker
        disabled={!onChangeCallback}
        selected={date}
        calendarStartDay={1}
        dateFormat='yyyy-MM-dd'
        onChange={onChange}
        showMonthDropdown
        showYearDropdown
        dropdownMode='select'
        locale={pl}
      />
      {isSendTry && (
        <div className='validationMessage'>{get(validationMessage, id, "")}</div>
      )}
    </div>
  );
};
export default DateInput;
