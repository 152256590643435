import { Input, ActionButton } from "modules/Form";
import FormProvider from "providers/FormProvider";
import { invoiceFormConfig } from "./config.js";
import { useInvoices } from "OdevFetch";

const InvoiceForm = () => {
  const { loading, payload, refetch, save } = useInvoices({ isSecure: true });

  if (loading) return <div>LOADING</div>;

  return (
    <div className='col-12 account-page'>
      <FormProvider config={invoiceFormConfig} payload={payload?.data || []}>
        <h1>Dane do faktury</h1>
        <div className='row'>
          <Input title='Nazwa firmy' id='company_name' className='col-12 col-md-6' />
          <Input title='NIP' id='nip' type='number' className='col-12 col-md-6' />
          <Input title='Adres' id='address' className='col-12 col-md-6' />
          <Input title='Miasto' id='city' className='col-12 col-md-6' />
          <Input title='Kod pocztowy' id='postal_code' className='col-12 col-md-6' />
          <Input title='Kraj' id='country' className='col-12 col-md-6' />
        </div>
        <div className='button'>
          <ActionButton
            type='add'
            confirmMessage='Czy jesteś pewien wprowadzonych zmian?'
            className='primary'
            onClick={async data => {
              await save({ body: data });
            }}
          >
            Zapisz
          </ActionButton>
        </div>
      </FormProvider>
    </div>
  );
};

export default InvoiceForm;
