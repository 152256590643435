import FiltersMain from "../../modules/FiltersMain/FiltersMain";
import FiltersBar from "../../modules/FiltersBar/FiltersBar";
import AccommodationList from "./AccommodationList/AccommodationList";
import "./Accommodation.scss";

const Accommodation = () => {
  return (
    <div className='accommodation-module'>
      <div className='col-12'>
        <FiltersMain />
      </div>
      <div className='container'>
        <div className='row accommodation'>
          <div className='col-12 col-lg-3'>
            <FiltersBar />
          </div>
          <div className='col-12 col-lg-9'>
            <AccommodationList />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Accommodation;
