import { useQuery } from "OdevFetch/useQuery";
import { returnFetch } from "OdevFetch/returnFetch";
import { lodgingsSave } from "./preSend";

const getQuery = query => {
  const values = [];
  if (query) {
    Object.keys(query).forEach(key => {
      values.push(`${key}=${query[key]}`);
    });
  }
  const preparedQuery = values.join("&");

  return values.length ? `?${preparedQuery}` : "";
};

const endpointGenerator = (endpoint, props, rest) => {
  const securePrefix = props?.isSecure ? "/secure" : "";
  let preparedEndpoint = `${process.env.REACT_APP_API_PATH}${securePrefix}/${endpoint}/`;
  if (props?.id) {
    preparedEndpoint += props.id + "/";
  }
  if (rest) {
    preparedEndpoint += `/${rest}`;
  }
  if (props?.query) {
    preparedEndpoint += getQuery(props.query);
  }

  return preparedEndpoint;
};

export const useAsks = props => {
  const { loading, payload, refetch } = useQuery({
    endpoint: endpointGenerator("message", props),
    isLazy: props?.isLazy,
  });

  const save = async ({ body }) => {
    const data = await returnFetch({
      endpoint: "message",
      body,
    });
    return data;
  };

  return {
    loading,
    payload,
    refetch,
    save,
  };
};

export const useLodgings = props => {
  const { loading, payload, refetch } = useQuery({
    endpoint: endpointGenerator("lodgings", props),
    isLazy: props?.isLazy,
  });

  const save = async ({ body }) => {
    const data = await returnFetch({
      endpoint: "secure/lodgings",
      body: lodgingsSave(body),
    });
    return data;
  };

  const buyPublication = async ({ body }) => {
    const data = await returnFetch({
      endpoint: "secure/lodgings/buyPublication",
      body,
    });
    return data;
  };

  const remove = async ({ id }) => {
    const data = await returnFetch({
      endpoint: `secure/lodgings/${id}/remove`,
    });
    await returnFetch({
      endpoint: `secure/removeAll/${id}`,
      isImagesEndpoint: true,
    });
    return data;
  };

  return {
    loading,
    payload,
    refetch,
    save,
    buyPublication,
    remove,
  };
};

export const useRooms = props => {
  const { loading, payload, refetch } = useQuery({
    endpoint: endpointGenerator("rooms", props),
    isLazy: props?.isLazy,
  });

  const save = async ({ body }) => {
    const data = await returnFetch({ endpoint: "secure/rooms/", body });
    return data;
  };

  const remove = async ({ id }) => {
    const data = await returnFetch({ endpoint: `secure/rooms/${id}/remove` });
    return data;
  };

  return {
    loading,
    payload,
    refetch,
    save,
    remove,
  };
};

export const useInvoices = props => {
  const { loading, payload, refetch } = useQuery({
    endpoint: endpointGenerator("invoices", props),
    isLazy: props?.isLazy,
  });

  const save = async ({ body }) => {
    const data = await returnFetch({ endpoint: "secure/invoices", body });
    return data;
  };

  return {
    loading,
    payload,
    refetch,
    save,
  };
};

export const useUsers = props => {
  const { loading, payload, refetch } = useQuery({
    endpoint: endpointGenerator("users", props),
    isLazy: props?.isLazy,
  });

  const save = async ({ body }) => {
    if (body.id) {
      const data = await returnFetch({ endpoint: "secure/user", body });
      return data;
    } else {
      const data = await returnFetch({ endpoint: "users", body });
      return data;
    }
  };

  const activate = async ({ body }) => {
    const data = await returnFetch({ endpoint: "activate", body });
    return data;
  };

  const resetPassword = async ({ email }) => {
    const data = await returnFetch({ endpoint: "reset-password", body: { email } });
    return data;
  };

  const setPassword = async ({ body }) => {
    const data = await returnFetch({ endpoint: "set-password", body });
    return data;
  };

  return {
    loading,
    payload,
    refetch,
    activate,
    save,
    resetPassword,
    setPassword,
  };
};

export const useGalleryFile = () => {
  const remove = async ({ id, fileName }) => {
    const data = await returnFetch({
      endpoint: `remove/${id}/${fileName}`,
      isImagesEndpoint: true,
    });
    return data;
  };

  const save = async ({ body }) => {
    const data = await returnFetch({
      endpoint: "upload",
      body,
      isImagesEndpoint: true,
    });
    return data;
  };

  return {
    remove,
    save,
  };
};

export const useDictionary = props => {
  const { loading, payload } = useQuery({
    endpoint: endpointGenerator("dictionary", props),
    isLazy: props?.isLazy,
  });

  return {
    loading,
    payload,
  };
};

export const usePoints = props => {
  const { loading, payload, refetch } = useQuery({
    endpoint: endpointGenerator("points", props),
    isLazy: props?.isLazy,
  });

  const save = async ({ body }) => {
    const data = await returnFetch({ endpoint: "secure/points/", body });
    return data;
  };

  const verify = async ({ token }) => {
    const data = await returnFetch({ endpoint: `secure/points/${token}` });
    return data;
  };

  return {
    loading,
    payload,
    refetch,
    save,
    verify,
  };
};

export const useReviews = props => {
  const { loading, payload } = useQuery({
    endpoint: endpointGenerator("reviews", props),
    isLazy: props?.isLazy,
  });

  const save = async ({ body }) => {
    const data = await returnFetch({ endpoint: "reviews", body });
    return data;
  };

  return {
    payload,
    loading,
    save,
  };
};

export const useGallery = () => {
  const remove = async ({ id }) => {
    const data = await returnFetch({ endpoint: `secure/gallery/${id}/remove` });
    return data;
  };

  const setPrimaryPhoto = async ({ body }) => {
    const data = await returnFetch({
      endpoint: `secure/gallery/setPrimaryPhoto`,
      body,
    });
    return data;
  };

  return {
    remove,
    setPrimaryPhoto,
  };
};

export const useCalendar = props => {
  const { loading, payload, refetch } = useQuery({
    endpoint: endpointGenerator("calendar", props),
    isLazy: props?.isLazy,
  });

  const save = async ({ body }) => {
    const data = await returnFetch({ endpoint: "secure/calendar", body });
    return data;
  };

  const remove = async ({ id }) => {
    const data = await returnFetch({ endpoint: `secure/calendar/${id}/remove` });
    return data;
  };

  return {
    loading,
    payload,
    refetch,
    save,
    remove,
  };
};

export const useContactForm = props => {
  const sendMail = async ({ body }) => {
    const data = await returnFetch({ endpoint: "sendMail", body });
    return data;
  };

  return {
    sendMail,
  };
};
