import { useForm } from "hooks";
import { get } from "lodash";

const Checkbox = ({ title, accessor, id, onChangeState }) => {
  const { onChange, data, validationMessage, isSendTry } = useForm();

  return (
    <div className='checkbox'>
      <div className='checkbox-element'>
        <input
          type='checkbox'
          onChange={e => {
            onChange({
              id,
              value: e.target.checked ? 1 : 0,
            });
            onChangeState && onChangeState();
          }}
          checked={accessor ? get(data, accessor) : get(data, id)}
        />
        <label>{title}</label>
        {isSendTry && (
          <div className='validationMessage'>{get(validationMessage, id, "")}</div>
        )}
      </div>
    </div>
  );
};

export default Checkbox;
