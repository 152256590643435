import { Link, NavLink, useNavigate, useLocation } from "react-router-dom";
import { useUser } from "hooks/useUser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import Image from "modules/Image";
import { useLodgeContext } from "hooks/useLodge";

import "./Navbar.scss";
import { useEffect, useRef, useState } from "react";

const Navbar = () => {
  const { isLogged, logOut } = useUser();
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const localisation = useLocation();
  const lodgeContext = useLodgeContext();

  const handleLogOut = async () => {
    await logOut();
    navigate("/");
  };

  const closeMenu = () => {
    lodgeContext.setFilters({});
    setIsOpen(false);
  };

  const headerClass = () => {
    if (localisation.pathname === "/") {
      return "home";
    } else if (localisation.pathname === "/login") {
      return "login";
    } else if (localisation.pathname === "/noclegi") {
      return "accommodation";
    } else {
      return "other";
    }
  };

  return (
    <div className={`header navbar-module ${headerClass()}`}>
      <div className='container header-content'>
        <Link to={"/#"} className='logo'>
          <Image src={process.env.REACT_APP_PUBLIC_URL + "/logo.png"} />
        </Link>

        <div className='navbar-buttons'>
          <div
            className='hamburger'
            onClick={() => setIsOpen(!isOpen)}
            style={{ display: isOpen ? "flex" : "" }}
          >
            <FontAwesomeIcon icon={faBars} style={{ fontSize: 30 }} />
          </div>
          <div className={`navbar-buttons-list ${!isOpen ? "is-hidden" : ""}`}>
            <NavLink to={"/noclegi"} onClick={closeMenu}>
              Noclegi
            </NavLink>
            {isLogged ? (
              <>
                <NavLink to={"/zarzadzaj/obiekty"} onClick={closeMenu}>
                  Moje nieruchomości
                </NavLink>
                <NavLink to={"/zarzadzaj/konto"} onClick={closeMenu}>
                  Konto
                </NavLink>
                <Link onClick={closeMenu && handleLogOut}>Wyloguj</Link>
              </>
            ) : (
              <NavLink to={"/login"}>Zaloguj się</NavLink>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
