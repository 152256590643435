import { createContext, useState } from "react";
import moment from "moment";
import { useFormState } from "../hooks/index";
import { DATE_FORMAT, ISO_DATE_FORMAT } from "../helpers/date";

export const FormContext = createContext({
  onChange: () => {},
  data: {},
  validationMessage: {},
  isSendTry: false,
  changeIsSendTry: () => {},
  isValid: true,
  onClear: () => {},
});

const adjustPayload = payload => {
  const adjustedPayload = payload;
  if (moment(payload.data, ISO_DATE_FORMAT, true).isValid()) {
    adjustedPayload.data = moment(payload.data, ISO_DATE_FORMAT).format(DATE_FORMAT);
  }

  return adjustedPayload;
};

const FormProvider = ({ children, payload = {}, config }) => {
  const adjustedPayload = adjustPayload(payload);

  const [data, onChange, onClear] = useFormState(adjustedPayload, config);
  const [isSendTry, changeIsSendTry] = useState(false);
  const validationMessage = {};
  config.forEach(setting => {
    if (!setting.validation) return;
    const currentValue = data[setting.id];
    setting.validation.forEach(testItem => {
      //if (!testItem.length) return;
      testItem.test(currentValue) && (validationMessage[setting.id] = testItem.message);
    });
  });

  const isValid = Object.keys(validationMessage).length === 0;

  return (
    <FormContext.Provider
      value={{
        data,
        onChange,
        onClear,
        validationMessage,
        isValid,
        isSendTry,
        changeIsSendTry,
      }}
    >
      {children}
    </FormContext.Provider>
  );
};

export default FormProvider;
