import { useState } from "react";
import RoomOfferts from "./RoomOfferts";
import Tabs from "modules/Tabs";
import Gallery from "./Gallery";
import ObjectDetails from "./ObjectDetails";
import { useLodgings } from "OdevFetch";
import { useParams, useLocation } from "react-router-dom";
import Points from "./Points/Points";
import Calendar from "pages/Room/RoomDescription/Calendar/Calendar";
import VerifyPayment from "modules/VerifyPayment/VerifyPayment";
import moment from "moment";

const ObjectsDetailsTabs = () => {
  const { id } = useParams();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const payed = queryParams.get("pay");

  const { payload, loading, refetch } = useLodgings({ id });
  const [selectedTab, setSelectedTab] = useState("objects");
  const [verifyPopup, setVerifyPopup] = useState({
    isOpen: !!payed,
    data: null,
  });

  if (loading) return <div>Loading</div>;

  const publicDate = payload?.data?.publication_date;
  console.log("payload", payload);

  const pointsConfig = moment(publicDate).isAfter(moment())
    ? [
        {
          id: "points",
          title: "Punkty",
          render: () => <Points />,
        },
      ]
    : [];

  return (
    <div className='object-details-module'>
      <Tabs
        externalState={{ selectedTab, setSelectedTab }}
        config={[
          {
            id: "objects",
            title: "Dane obiektu",
            render: () => <ObjectDetails />,
          },
          {
            id: "rooms",
            title: "Lista pokoi",
            render: () => <RoomOfferts />,
          },
          {
            id: "gallery",
            title: "Galeria",
            render: () => (
              <Gallery key='Gallery' images={payload.data.gallery} refetch={refetch} />
            ),
          },
          ...pointsConfig,
          {
            id: "calendar",
            title: "Kalendarz",
            render: () => <Calendar />,
          },
        ]}
      />
      {verifyPopup.isOpen && (
        <VerifyPayment
          onClose={() => setVerifyPopup({ ...verifyPopup, isOpen: false })}
        />
      )}
    </div>
  );
};

export default ObjectsDetailsTabs;
