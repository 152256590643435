import "./OfferBox.scss";
import Image from "modules/Image";

import { useNavigate } from "react-router-dom";

const OfferBox = ({ name, address, description, min_price, reviews, id, gallery }) => {
  const navigate = useNavigate();
  const goToLodge = () => {
    navigate(`/nocleg/${id}/opis`);
  };

  const rating = reviews?.length
    ? (
        reviews.reduce((sum, review) => sum + review.rating, 0) / reviews.length
      ).toFixed(2)
    : "Brak";

  const images = {
    primary: gallery?.[0],
    rest: gallery?.slice(1, Math.min(4, gallery.length)),
  };

  return (
    <div className='offer-box-module'>
      <div className='image'>
        <Image
          alt='Zdjęcie obiektu'
          src={`${process.env.REACT_APP_IMAGES_PATH}${id}/${
            gallery.find(item => item?.is_primary)?.image_name
          }`}
          className='img'
        />
      </div>
      <div className='description'>
        <div className='first-line'>
          <h5>{name}</h5>
          <h6>
            ocena <span> {rating}</span>
          </h6>
        </div>

        <h6>{address}</h6>
        <p>{description}</p>
        <div className='end-line'>
          <h6>Cena od {min_price}</h6>
          <button onClick={() => goToLodge()} className='primary'>
            Zobacz nocleg
          </button>
        </div>
      </div>
    </div>
  );
};

export default OfferBox;
