import Carousel from "better-react-carousel";
import "./Gallery.scss";
import SmartGallery from "modules/SmartGallery";

const Gallery = ({ gallery }) => {
  return (
    <div className='gallery-module'>
      <h4>Galeria</h4>
      <SmartGallery images={gallery} />
    </div>
  );
};

export default Gallery;
