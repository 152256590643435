import "./Pricing.scss";

const Pricing = ({ data }) => {
  const rooms = data.rooms;

  return (
    <div className="pricing-module">
      <h5>Cennik</h5>
      <div className="pricing-wrapper">
        {rooms.map((room) => (
          <div key={room.id} className="pricing-single col-md-4 col-12">
            <h5>{room.type}</h5>
            <span>{room.price_day},-</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Pricing;
