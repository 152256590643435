import { get } from "lodash";

import { useState } from "react";

const createInitialState = (payload, config) => {
  const data = {};
  config.forEach(value => {
    data[value.id] = get(payload, value.id, value.default);
  });

  return data;
};

const onFormStateChange = (data, { id, value }, setData) => {
  setData({
    ...data,
    [id]: value,
  });
};

const onFormStateMultiChange = (data, multiChange, setData) => {
  setData({
    ...data,
    ...multiChange,
  });
};

export const useFormState = (payload, config) => {
  const [data, setData] = useState(createInitialState(payload, config));
  const onClear = () => {
    setData(createInitialState(payload, config));
  };

  const onChange = value => {
    if (
      typeof value.id !== undefined &&
      typeof value.value !== undefined &&
      Object.keys(value).length === 2
    ) {
      return onFormStateChange(data, value, setData);
    } else {
      return onFormStateMultiChange(data, value, setData);
    }
  };
  return [data, onChange, onClear];
};
