import "./PublicationManager.scss";
import BuyPublicationPopup from "./BuyPublicationPopup";
import { useState } from "react";
import moment from "moment";
import { useSidebar } from "hooks/useSidebar";

const PublicationManager = () => {
  const { sidebarData } = useSidebar();
  const { publication_date } = sidebarData;
  const isPublished = moment(publication_date).isAfter(moment());
  const [buyPublicationPopupState, setBuyPublicationPopupState] = useState({
    isOpen: false,
  });

  const getPublishedEndInfo = () => {
    return (
      <div className='published'>
        Ogłoszenie opublikowane do dnia:{" "}
        <div className='date'>{moment(publication_date).format("YYYY-MM-DD")}</div>
      </div>
    );
  };

  const getUnpublishedContent = () => {
    return (
      <div className='unpublish'>
        <p>Twoje ogłoszenie jest niewidoczne.</p>
        <button
          className='primary'
          onClick={() =>
            setBuyPublicationPopupState({
              ...buyPublicationPopupState,
              isOpen: true,
            })
          }
        >
          Kliknij aby opublikować!
        </button>
      </div>
    );
  };

  return (
    <div className='publication-manager'>
      {isPublished ? getPublishedEndInfo() : getUnpublishedContent()}
      {buyPublicationPopupState.isOpen && (
        <BuyPublicationPopup
          onClose={() => {
            setBuyPublicationPopupState({ ...buyPublicationPopupState, isOpen: false });
          }}
        />
      )}
    </div>
  );
};

export default PublicationManager;
