import { VALIDATION_SCHEMA } from "modules/Form/validationSchema";

export const contactConfig = [
  {
    id: "email",
    default: "",
    validation: [...VALIDATION_SCHEMA.email],
  },
  {
    id: "name",
    default: "",
    validation: [...VALIDATION_SCHEMA.name],
  },
  {
    id: "subject",
    default: "",
    validation: [...VALIDATION_SCHEMA.name],
  },
  {
    id: "text",
    default: "",
    validation: [...VALIDATION_SCHEMA.description],
  },
];