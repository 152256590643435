import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./Login.scss";
import { useUser } from "../../hooks/useUser";

const Login = () => {
  const navigate = useNavigate();
  const { logIn: logInHook } = useUser();
  const [validForm, setValidForm] = useState(true);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleLogin = async event => {
    event.preventDefault();
    const output = await logInHook(email, password);
    if (!output) {
      setValidForm(false);
    }
    if (output.status === "success") {
      navigate("/zarzadzaj/konto");
    } else {
      setValidForm(false);
    }
  };

  return (
    <div className='login-module'>
      <div className='login-container'>
        <h1 className='login-title'>Zaloguj się do Rentali</h1>
        {!validForm && <div className='login-error'>Wprowadzono niepoprawne dane</div>}
        <form onSubmit={handleLogin}>
          <div className='form-group'>
            <label htmlFor='email'>Email lub login</label>
            <input
              type='text'
              id='email'
              value={email}
              onChange={e => setEmail(e.target.value)}
            />
          </div>
          <div className='form-group'>
            <label htmlFor='password'>Hasło</label>
            <input
              type='password'
              id='password'
              value={password}
              onChange={e => setPassword(e.target.value)}
            />
          </div>
          <div className='register-link'>
            <h4>
              Nie posiadasz konta?{" "}
              <span onClick={() => navigate("/register")}>Zarejestruj się</span>
            </h4>
            <Link to='/reset-password' className='forgot-password-link-checkbox'>
              Zresetuj hasło
            </Link>
          </div>
          <div className='form-buttons'>
            <button type='submit' className='login-button'>
              Zaloguj
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
