import { format } from "date-fns";
import moment from "moment";

export const postDateSave = data => {
  // const date = new Date(data);
  // const d = date.getDate();
  // const m = date.getMonth() + 1;
  // const y = date.getFullYear();

  // return (y + '-' + (m <= 9 ? '0' + m : m) + '-' + (d <= 9 ? '0' + d : d));

  return format(new Date(data), "yyyy-MM-dd");
};

export const DATE_FORMAT = "YYYY-MM-DD";
export const HOURS_FORMAT = "HH:mm";
export const TIME_FORMAT = HOURS_FORMAT;

export const ISO_DATE_FORMAT = "yyyy-MM-ddTHH:mm:ss.sssZ";

export const MONTH_NAMES = [
  "Styczeń",
  "Luty",
  "Marzec",
  "Kwiecień",
  "Maj",
  "Czerwiec",
  "Lipiec",
  "Sierpień",
  "Wrzesień",
  "Październik",
  "Listopad",
  "Grudzień",
];

export const translateDay = day => {
  let days = {
    1: "PN",
    2: "WT",
    3: "ŚR",
    4: "CZ",
    5: "PT",
    6: "SO",
    7: "ND",
  };
  return day < 1 || day > 7 ? null : days[day];
};

export const SHORT_MONTH_NAMES = {
  1: "STY",
  2: "LUT",
  3: "MAR",
  4: "KWI",
  5: "MAJ",
  6: "CZE",
  7: "LIP",
  8: "SIE",
  9: "WRZ",
  10: "PAŹ",
  11: "LIS",
  12: "GRU",
};

export const translateDateToShortMonth = date => {
  const month = date.format("M");
  return month < 1 || month > 12 ? null : SHORT_MONTH_NAMES[month];
};

export const isDateBetween = (startDate, endDate, checkDate) => {
  const start = moment(startDate);
  const end = moment(endDate);
  const check = moment(checkDate);
  return check.isBetween(start, end, null, "[]");
};
