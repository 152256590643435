import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import "./CalendarNav.scss";

const CalendarNav = ({ firstDate, onSetFirstDate, size }) => {
  const getEndDate = () => {
    return moment(firstDate)
      .add(size - 1, "days")
      .format();
  };

  const nextPeriod = () => {
    onSetFirstDate(daysToDate(firstDate, size, true));
  };

  const prevPeriod = () => {
    if (!dateIsBeforeToday()) {
      onSetFirstDate(daysToDate(firstDate, size, false));
    }
  };

  const daysToDate = (date, days, add) => {
    return moment(date)
      .add(add ? days : -days, "days")
      .format();
  };

  const getCurrentYear = () => {
    let yearStart = moment(firstDate).year();
    let yearEnd = moment(getEndDate()).year();
    return yearStart === yearEnd ? yearStart.toString() : `${yearStart} - ${yearEnd}`;
  };

  const dateIsBeforeToday = () => {
    return moment(firstDate).isBefore(moment());
  };

  return (
    <div className='calendar-nav d-flex justify-content-between align-items-center'>
      <div
        className={`calendar-texts nav-button d-flex user-select-none ${
          dateIsBeforeToday() && "disable-nav-button"
        }`}
        onClick={() => prevPeriod()}
      >
        <div className='icon me-3 px-2 py-1'>
          <FontAwesomeIcon icon={faAngleLeft} />
        </div>
        <div className='nav-state'>Pokaż poprzednie</div>
      </div>
      <div className='calendar-texts'>{getCurrentYear()}</div>
      <div
        className='calendar-texts nav-button d-flex user-select-none'
        onClick={() => nextPeriod()}
      >
        <div className='nav-state'>Przejdź dalej</div>
        <div className='icon ms-3 px-2 py-1'>
          <FontAwesomeIcon icon={faAngleRight} />
        </div>
      </div>
    </div>
  );
};

export default CalendarNav;
