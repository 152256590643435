import React from "react";
import { useState } from "react";
import { useEffect } from "react";

const Image = ({ src, alt, className }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);

  const placeholder = "/placeholder.jpg";
  const loading = "/Loading_icon.gif";

  const handleImageLoad = () => {
    setIsLoading(false);
  };

  const handleImageError = () => {
    setIsLoading(false);
    setHasError(true);
  };

  useEffect(() => {
    const image = new window.Image();
    image.src = src;

    image.onload = () => {
      setIsLoading(false);
    };

    image.onerror = () => {
      setIsLoading(false);
      setHasError(true);
    };
  }, [src]);

  return (
    <>
      {isLoading && !hasError && <img src={loading} alt='loading' />}
      {!isLoading && !hasError && (
        <img
          onLoad={handleImageLoad}
          onError={handleImageError}
          src={src}
          alt={alt}
          className={className}
          style={isLoading ? { display: "none" } : {}}
        />
      )}
      {hasError && <img src={placeholder} alt='Placeholder' />}
    </>
  );
};

export default Image;
