import "./Points.scss";
import { usePoints } from "OdevFetch";
import PointsSingle from "./PointsSingle";
import { useState } from "react";
import AddPoints from "modules/AddPoints/AddPoints";
import { useParams } from "react-router-dom";

const Points = () => {
  const { id: lodgeId } = useParams();
  const { payload, loading, refetch } = usePoints({
    isSecure: true,
    query: { lodge_id: lodgeId },
  });
  const [modalState, setModalState] = useState({
    isOpen: false,
  });

  if (loading) return <div>Loading</div>;

  const { points, cityPodium, worldPodium, worldPoints, cityPoints } = payload.data;

  function getPosition(rankingPoints) {
    console.log(rankingPoints);
    const current = points;
    let currentPosition = 0;

    for (let i = 0; i < rankingPoints.length; i++) {
      if (Number(rankingPoints[i]) <= Number(current)) {
        currentPosition = i + 1;
        break;
      }
    }

    console.log({ currentPosition, current, rankingPoints });

    return currentPosition;
  }

  return (
    <div className='points-module'>
      <div className='points-header row'>
        <div className='details col-12 col-md-3'>
          <h1>{getPosition(cityPoints)}</h1>
          <p>Pozycja w Twoim mieście</p>
        </div>
        <div className='details col-12 col-md-3'>
          <h1>{getPosition(worldPoints)}</h1>
          <p>Generalna pozycja</p>
        </div>
        <div className='details col-12 col-md-3'>
          <h1>{points || "0"}</h1>
          <p>Twoje punkty</p>
        </div>
        <div className='col-12 col-xl-3'>
          <button
            className='primary'
            onClick={() =>
              setModalState({
                isOpen: true,
                startPoints: 1,
              })
            }
          >
            Zakup punkty
          </button>
        </div>
      </div>
      <div className='points-rank'>
        <h1>Ranking w Twojej miejscowości</h1>
        {cityPodium.map((item, index) => (
          <PointsSingle
            podiumItem={item}
            lodgePoints={points}
            place={index}
            key={index}
            openModal={() =>
              setModalState({
                isOpen: true,
                startPoints: item.points - points,
              })
            }
          />
        ))}
      </div>
      <div className='points-rank'>
        <h1>Ranking generalny</h1>
        {worldPodium.map((item, index) => {
          return (
            <PointsSingle
              podiumItem={item}
              lodgePoints={points}
              place={index}
              key={index}
              startPoints={modalState.startPoints}
              openModal={() =>
                setModalState({
                  isOpen: true,
                  startPoints: item.points - points,
                })
              }
            />
          );
        })}
      </div>
      {modalState.isOpen && (
        <AddPoints
          closeAction={() => {
            refetch();
            setModalState({ isOpen: false });
          }}
          startPoints={modalState.startPoints}
          currentPoints={points}
          cityPoints={cityPoints}
          worldPoints={worldPoints}
        />
      )}
    </div>
  );
};

export default Points;
