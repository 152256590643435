import "./Gallery.scss";
import PhotoImport from "./PhotoImport";
import SmartGallery from "modules/SmartGallery";
import { useGalleryFile } from "OdevFetch";
import { useGallery } from "OdevFetch";
import { useSidebar } from "hooks/useSidebar";


const Gallery = ({ images, refetch }) => {
  const { remove } = useGalleryFile();
  const { setPrimaryPhoto } = useGallery();
  const { setSidebar, sidebarData } = useSidebar();

  const onRemoveImage = async (e, image) => {
    e.stopPropagation();
    await remove({ id: image.lodging_id, fileName: image.image_name });
    await refetch();
  };

  const onSelectPrimary = async (e, image) => {
    e.stopPropagation();
    await setPrimaryPhoto({body: image});
    setSidebar({
      ...sidebarData,
      gallery: `${process.env.REACT_APP_IMAGES_PATH}${image.path}`
    });
    await refetch();
  }

  return (
    <div className='gallery-module'>
      <PhotoImport refetch={refetch} />

      <SmartGallery images={images} onRemove={onRemoveImage} onSelectPrimary={onSelectPrimary}/>
    </div>
  );
};

export default Gallery;
