import "./PointsSingle.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import Image from "modules/Image";

const PointsSingle = ({ podiumItem, lodgePoints = 0, place, openModal }) => {
  const { image_name, name, city, address, points, id } = podiumItem;

  const missingPoints = points - lodgePoints;

  return (
    <div className='pointsSingle-module'>
      <div className='pointsSingle-wrapper row'>
        <div className='image'>
          <div className='image-placeholder'>
            <Image src={`${process.env.REACT_APP_IMAGES_PATH}${id}/${image_name}`} />{" "}
          </div>
        </div>
        <div className='city col-2 col-md-3'>
          <h4>{name}</h4>
          <h5>
            <FontAwesomeIcon icon={faLocationDot} />
            {city}, {address}
          </h5>
        </div>
        <div className='place col-2 col-md-2'>
          <h2>{place + 1}</h2>
          <h3>miejsce</h3>
        </div>
        <div className='points col-2'>
          <h2>{missingPoints > 0 && missingPoints}</h2>
          <h3>
            {missingPoints > 0
              ? "Brakujące punkty"
              : "Nie posiadasz brakujących punktów"}
          </h3>
        </div>
        <div className='button col-12 col-xl-3'>
          {missingPoints > 0 && (
            <button type='submit' className='outline' onClick={openModal}>
              Dodaj brakujące punkty
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default PointsSingle;
