import Modal from "modules/Modal/Modal";
import "./BuyPublicationPopup.scss";
import { useParams } from "react-router-dom";
import { useLodgings } from "OdevFetch";
import { useUser } from "hooks";

const BuyPublicationPopup = ({ onClose }) => {
  const params = useParams();
  const { userData } = useUser();

  const lodgeId = params?.["*"].split("/")[1];

  const { buyPublication } = useLodgings({
    isLazy: true,
  });

  const buyPublicationHandler = async type => {
    const payload = await buyPublication({
      body: {
        type,
        lodge_id: lodgeId,
        email: userData.email,
      },
    });

    if (payload?.link) {
      window.location.href = payload.link;
    }
  };

  return (
    <Modal
      title='Opublikuj ogłoszenie'
      className='add-publication-module'
      closeAction={onClose}
    >
      <>
        <p>
          U nas możesz się czuć komfortowo. Sam wybierz opcję abonamentu i określ czas
          jego trwania. Wybierz najkorzystniejszą ofertę dla siebie:
        </p>
        <div className='choice'>
          <p>
            3 miesiące
            <br />
            <strong>99 zł brutto</strong>
            <br />
            <button className='primary' onClick={() => buyPublicationHandler("type1")}>
              Wykup!
            </button>
          </p>

          <p>
            6 miesięcy
            <br />
            <strong>199 zł brutto</strong>
            <br />
            <button className='primary' onClick={() => buyPublicationHandler("type2")}>
              Wykup!
            </button>
          </p>
          <p>
            12 miesięcy
            <br /> <strong>399 zł brutto</strong>
            <br />
            <button className='primary' onClick={() => buyPublicationHandler("type3")}>
              Wykup!
            </button>
          </p>
        </div>

        <p className='accept-alert'>
          Dokonując zakupu akceptujesz warunki regulaminu.
          <br />
          Publikacja nastąpi po potwierdzeniu przelewu.
        </p>
      </>
    </Modal>
  );
};

export default BuyPublicationPopup;
