import { VALIDATION_SCHEMA } from "modules/Form/validationSchema";

export const passwordValidate = (password, repeatPassword) => {
  const result = password === repeatPassword ? true : false;
  return result;
};

export const registerConfig = [
  {
    id: "email",
    default: "",
    validation: [...VALIDATION_SCHEMA.email],
  },
  {
    id: "login",
    default: "",
    validation: [...VALIDATION_SCHEMA.name],
  },
  {
    id: "password",
    default: "",
  },
  {
    id: "repeat_password",
    default: "",
  },
];
