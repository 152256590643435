export const filtersBarConfig = [
  {
    id: "type",
    default: false,
  },
  {
    id: "facilities",
    default: false,
  },
  {
    id: "min_price",
    default: "",
  },
  {
    id: "max_price",
    default: "",
  },
];
