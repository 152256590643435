export const filterConfig = [
  {
    id: "city",
    default: "",
  },
  {
    id: "address",
    default: "",
  },
  {
    id: "min_date",
    default: "",
  },
  {
    id: "max_date",
    default: "",
  },
  {
    id: "people_count",
    default: "",
  },
  // {
  //   id: "type",
  //   default: "",
  // },
  // {
  //   id: "facilities",
  //   default: "",
  // },
];
