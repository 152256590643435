import React from "react";
import OfferBox from "./OfferBox/OfferBox";
import "./SampleOffers.scss";
import { useNavigate } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useLodgings } from "OdevFetch";
import { useLodgeContext } from "hooks/useLodge";

const SampleOffers = () => {
  const lodgeContext = useLodgeContext();
  const navigate = useNavigate();
  const goToAccommodations = () => {
    lodgeContext.setFilters({});

    navigate("/noclegi");
  };
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 720,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const { payload, loading } = useLodgings({
    query: { size: 8 },
  });

  if (loading) return <div>LOADING</div>;

  const offers = payload.data;

  return (
    <div className='sample-offers-module container'>
      <div className='row'>
        <div className='col-12 offers-head'>
          <h2 className='offers-title'>Proponowane</h2>
          <h5 className='offers-description'>
            Odkryj nasze starannie wybrane propozycje miejsc, które zapewnią Ci
            niezapomniane chwile podczas nadchodzących wakacji!
          </h5>
        </div>
        {/* <Slider {...settings}> */}

        {offers.map((offer, index) => (
          <div key={index} className='col-12 col-md-6 col-lg-4 col-xxl-3'>
            <OfferBox {...offer} />
          </div>
        ))}
        {/* </Slider> */}
        <div className='col-12 action-button'>
          <button
            className='primary'
            onClick={() => {
              goToAccommodations();
            }}
          >
            Zobacz wszystko
          </button>
        </div>
      </div>
    </div>
  );
};

export default SampleOffers;
