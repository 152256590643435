import "./Room.scss";
import RoomDescription from "./RoomDescription/RoomDescription";
import RoomTitle from "./RoomTitle/RoomTitle";
import { useLodgings } from "OdevFetch";
import { useParams } from "react-router-dom";
import Image from "modules/Image";

const Room = () => {
  const { id } = useParams();
  const { payload, loading, refetch } = useLodgings({ id });

  if (loading) return <div>Loading</div>;
  console.log(payload);
  const gallery = payload?.data?.gallery;
  const primaryPhoto = gallery.find(item => item?.is_primary)?.image_name;

  return (
    <div className='container'>
      <div className='row room'>
        <div className='col-12'>
          <RoomTitle data={payload.data}></RoomTitle>
        </div>
        <div className='col-12 room-image'>
          <Image
            alt='apartment'
            src={`${process.env.REACT_APP_IMAGES_PATH}${id}/${primaryPhoto}`}
          />
        </div>
        <div className='col-12'>
          <RoomDescription data={payload.data} refetch={refetch} />
        </div>
      </div>
    </div>
  );
};

export default Room;
