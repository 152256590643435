import "./Opinions.scss";
import Opinion from "./Opinion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import AddOpinion from "modules/AddOpinion/AddOpinion";
import { useState } from "react";

const Opinions = ({ reviews, refetch }) => {
  const [isOpenAddOpinion, setIsOpenAddOpinion] = useState(false);
  const closeHandler = () => {
    setIsOpenAddOpinion(false);
    refetch();
  };
  return (
    <div className='opinions-module'>
      <div className='opinions-header'>
        <h5>Opinie</h5>
        <button className='primary' onClick={() => setIsOpenAddOpinion(true)}>
          Dodaj opinię
        </button>
      </div>
      <div className='opinions-wrapper'>
        {reviews.map(item => (
          <Opinion
            author={item.author}
            stars={item.rating}
            comment={item.comment}
            publicDate={item.created_at}
          />
        ))}
      </div>
      {isOpenAddOpinion && <AddOpinion closeHandler={closeHandler} />}
    </div>
  );
};

export default Opinions;
