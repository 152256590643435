import { createContext, useState } from "react";

export const SidebarContext = createContext({});

const SidebarProvider = ({ children }) => {
  const [sidebarData, setSidebarData] = useState({});

  return (
    <SidebarContext.Provider
      value={{
        sidebarData,
        setSidebarData,
      }}
    >
      {children}
    </SidebarContext.Provider>
  );
};

export default SidebarProvider;
