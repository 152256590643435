import { useState } from "react";
import "./AskForSleep.scss";
import AskForLodge from "modules/AskForLodge/AskForLodge";
import FormProvider from "providers/FormProvider";
import { askForLodgeConfig } from "./config";
import { ActionButton, Input } from "modules/Form";
import { useDictionary } from "OdevFetch";

const AskForSleep = ({ data }) => {
  const [openModal, setOpenModal] = useState(false);
  const [formData, setFormData] = useState(null);
  const { payload, loading } = useDictionary();

  if (loading) return <div>Loading</div>;
  const OBJECT_TYPE = payload?.data?.filter(item => item.type === "OBJECT_TYPE");

  const submitHandler = (formInputs) => {
    setFormData(formInputs);
    setOpenModal(true);
  };

  const closeAction = () => {
    setOpenModal(false);
  };

  return (
    <div className="ask-sleep-module">
      <h4>ZAPYTANIE O NOCLEG</h4>
      <div className="row ask-content">
        <FormProvider config={askForLodgeConfig}>
          <div className="col-12 input-item">
            <Input id="email" placeholder="Email" className="input-margin" />
          </div>
          <div className="col-6 input-item">
            <Input id="start_date" placeholder="Data od" className="input-margin" type="date" title="Data od" />
          </div>
          <div className="col-6 input-item">
            <Input id="finish_date" placeholder="Data do" className="input-margin" type="date" title="Data do" />
          </div>
          <div className="col-6 input-item">
            <Input id="adult" placeholder="Dorośli (ilość)" className="input-margin" />
          </div>
          <div className="col-6 input-item">
            <Input
              id="child"
              placeholder="Dzieci (ilość)"
              className="input-margin"
            />
          </div>
          <div className="col-12 ask-btn">
            <ActionButton className="primary" onClick={(data) => submitHandler(data)}>
              Wyślij
            </ActionButton>
          </div>
        </FormProvider>
      </div>
      {openModal && <AskForLodge data={data} closeAction={closeAction} formData={formData} objectType={OBJECT_TYPE} />}
    </div>
  );
};

export default AskForSleep;
