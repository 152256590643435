export const SORT_LIST_CONFIG = [
  {
    title: "Cena rosnąco",
    type: "min_price",
    direction: "asc",
  },
  {
    title: "Cena malejąco",
    type: "min_price",
    direction: "desc",
  },
  {
    title: "Nazwa rosnąco",
    type: "name",
    direction: "asc",
  },
  {
    title: "Nazwa malejąco",
    type: "name",
    direction: "desc",
  },
];
