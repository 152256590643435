import { usePoints } from "OdevFetch";
import "./AddPoints.scss";
import Modal from "modules/Modal/Modal";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useUser } from "hooks";

const AddPoints = ({
  closeAction,
  startPoints,
  cityPoints = [],
  worldPoints = [],
  currentPoints,
}) => {
  const { id: lodgeId } = useParams();
  const { userData } = useUser();
  const plnConverter = 1.23; // stała do zamiany punktów na złotówki, do ustalenia
  const [pointsState, setPointsState] = useState(startPoints);

  const { save } = usePoints({
    isLazy: true,
    query: { lodge_id: lodgeId },
  });

  function getPosition(rankingPoints) {
    const current = currentPoints + pointsState;
    let currentPosition = 0;

    for (let i = 0; i < rankingPoints.length; i++) {
      if (Number(rankingPoints[i]) <= Number(current)) {
        currentPosition = i + 1;
        break;
      }
    }

    return currentPosition;
  }

  const handleOperators = operate => {
    setPointsState(operate === "plus" ? pointsState + 1 : pointsState - 1);
  };

  const buyPointsHandler = async () => {
    const payload = await save({
      body: {
        amount: pointsState,
        lodge_id: lodgeId,
        email: userData.email,
      },
    });

    if (payload?.link) {
      window.location.href = payload.link;
    }
  };

  return (
    <Modal closeAction={closeAction} title='Zakup punkty' className='add-points-module'>
      <div className='body-wrapper'>
        <div className='points-wrapper row'>
          <div className='points col-12 col-md-7 pt-3'>
            <div className='points-header'>
              <h5>Punkty</h5>
            </div>
            <div className='points-input'>
              <input
                value={pointsState}
                onChange={e => {
                  setPointsState(Number(e.target.value));
                }}
                type='number'
              ></input>
              <div className='plus'>
                <span onClick={() => handleOperators("plus")}>+</span>
              </div>
              <div className='minus'>
                <span onClick={() => handleOperators("minus")}>-</span>
              </div>
            </div>
          </div>
          <div className='payment col-12 col-md-4 pt-3'>
            <h4>Kwota</h4>
            <h5>{plnConverter * pointsState} zł brutto</h5>
          </div>
        </div>
        <div className='ranking-wrapper'>
          <h2>Ranking</h2>
          <div className='city-rank'>
            <h3>Twoja miejscowość</h3>
            <h4>
              <span>{getPosition(cityPoints)} </span> miejsce
            </h4>
          </div>
          <div className='general-rank'>
            <h3>Generalny</h3>
            <h4>
              <span>{getPosition(worldPoints)}</span> miejsce
            </h4>
          </div>
        </div>
        <div className='btns-wrapper'>
          <button className='primary' onClick={buyPointsHandler}>
            Zapłać
          </button>
          <button className='secondary' onClick={() => closeAction()}>
            Anuluj
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default AddPoints;
