import Ad from "../../modules/Ad/Ad";
import FiltersMain from "../../modules/FiltersMain/FiltersMain";
import SampleOffers from "./SampleOffers/SampleOffers";
import CategoryBlocks from "../../modules/CategoryBlocks/CategoryBlocks";

const HomePage = () => {
  return (
    <div>
      <div className='row'>
        <FiltersMain titleShowed={true} isShownStart={true} />

        <div className='col-12'>
          <div className='container'>
            <CategoryBlocks />
          </div>
        </div>
        <div className='col-12'>
          <SampleOffers />
        </div>
        <div className='col-12'>
          <Ad />
        </div>
      </div>
    </div>
  );
};

export default HomePage;
