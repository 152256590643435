import { useEffect, useRef, useState } from "react";
import { useForm } from "hooks";

import "./MultiSelect.scss";
import { get } from "lodash";

const MultiSelect = ({ title, dictionary, type: propsType, id }) => {
  const type = propsType || "list";
  const { onChange: onChangeForm, data, validationMessage, isSendTry } = useForm();

  const [isDropdownShow, setDropdawnShow] = useState(false);

  const dropdownRef = useRef(null);

  useEffect(() => {
    if (type === "dropdown") {
      const handleClickOutside = event => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
          setDropdawnShow(false);
        }
      };

      document.addEventListener("click", handleClickOutside);

      return () => {
        document.removeEventListener("click", handleClickOutside);
      };
    }
  }, []);

  const getStateValues = () => {
    return data[id] || [];
  };

  const isPositionChecked = value => {
    const checkedValues = getStateValues();
    return checkedValues.includes(value);
  };

  const toggleOption = option => {
    const checkedValues = getStateValues();
    const isChecked = checkedValues.includes(option);

    if (isChecked) {
      onChangeForm({
        id,
        value: checkedValues.filter(item => item != option)
      });
    } else {
      const filteredValues = checkedValues.filter(item => !!item.length);
      onChangeForm({ id, value: [...filteredValues, option] });
    }
  };

  const getValueText = () => {
    const checkedValues = getStateValues();

    const filteredValues = checkedValues.filter(item => item.length);

    const result = filteredValues.map(
      key => dictionary.find(item => item.key == key).value
    );
    return result;
  };

  if (type === "list") {
    return (
      <>
        {isSendTry && (
          <div className="validationMessage">{get(validationMessage, id, "")}</div>
        )}
        <div className="multi-select-module list">
          {title}
          {dictionary.map(option => (
            <label key={option.key} style={{ display: "block" }}>
              <input
                type="checkbox"
                value={option.key}
                checked={isPositionChecked(option.key)}
                onChange={() => toggleOption(option.key)}
              />
              {option.value}
            </label>
          ))}

        </div>
      </>
    );
  }

  return (
    <>
      {isSendTry && (
        <div className="validationMessage">{get(validationMessage, id, "")}</div>
      )}
      <div className="multi-select-module dropdown" ref={dropdownRef}>
        {title}
        <input
          readOnly
          className="presenter"
          type="text"
          onClick={() => {
            setDropdawnShow(true);
          }}
          value={getValueText()}
        />
        <div className={`dropdown-content ${isDropdownShow ? "active" : ""}`}>
          {dictionary.map(option => (
            <label key={option.key}>
              <input
                type="checkbox"
                value={option.key}
                checked={isPositionChecked(option.key)}
                onChange={() => toggleOption(option.key)}
              />
              {option.value}
            </label>
          ))}

        </div>
      </div>
    </>
  );
};

export default MultiSelect;
