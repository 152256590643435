import { useForm } from "hooks";
import { get } from "lodash";

const Input = ({
  title,
  id,
  accessor,
  type,
  placeholder,
  readOnly,
  className,
  onDisabled,
}) => {
  const { onChange, data, validationMessage, isSendTry } = useForm();

  const isDisabled = onDisabled && onDisabled(data);

  return (
    <div className={`input ${className}`}>
      {title ? <label>{title}</label> : undefined}
      <input
        value={accessor ? get(data, accessor) : get(data, id)}
        type={type || "text"}
        disabled={!onChange || isDisabled}
        placeholder={placeholder}
        onChange={event => onChange({ id: id, value: event.target.value })}
        readOnly={readOnly}
      />
      {isSendTry && (
        <div className='validationMessage'>{get(validationMessage, id, "")}</div>
      )}
    </div>
  );
};

export default Input;
