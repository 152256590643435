import Modal from "modules/Modal/Modal";
import { ActionButton, Dropdown, Input, Textarea, MultiSelect } from "modules/Form";
import FormProvider from "providers/FormProvider";
import { formConfig } from "./config";
import { useLodgings } from "OdevFetch";
import { useDictionary } from "OdevFetch";
import "./AddLodge.scss";
import Image from "modules/Image";

const AddLodge = ({ closeAction, data, refetch }) => {
  const { payload: facilitiesDictionaryPayload, loading: facilitiesDictionaryLoading } =
    useDictionary({
      query: { type: "facilities" },
    });
  const { payload: objectTypeDictionaryPayload, loading: objectTypeDictionaryLoading } =
    useDictionary({
      query: { type: "object_type" },
    });

  const { save } = useLodgings({ isSecure: true });
  const saveHandler = async data => {
    await save(data);
    window.location.reload();
  };
  const isEdit = !!data;

  if (facilitiesDictionaryLoading || objectTypeDictionaryLoading)
    return <div>Loading</div>;

  const facilitiesDictionary = facilitiesDictionaryPayload?.data;
  const objectTypeDictionary = objectTypeDictionaryPayload?.data;

  return (
    <>
      <Modal
        title={isEdit ? "Edytuj obiekt" : "Nowy obiekt"}
        className='form add-lodge-module'
        closeAction={() => {
          closeAction();
        }}
      >
        <FormProvider config={formConfig} payload={data || []}>
          <div className='row lodge-form'>
            <div className='col-12 row lodge-header'>
              {/* TODO: create primary functionality */}
              {/* <div className='col-12 col-md-3 lodge-image'>
                <Image src='/room.jpg' alt='room photo' />
              </div> */}
              <div className='col-12 col-md-9'>
                <Input title='Nazwa obiektu' id='name' className='lodge-input' />
                <div className='row '>
                  <Input
                    title='Miasto'
                    id='city'
                    className={"lodge-input col-md-6 col-12"}
                  />
                  <Input
                    title='Adres'
                    id='address'
                    className='lodge-input col-md-6 col-12'
                  />
                </div>
              </div>
              <div className='col-12 col-md-3'>
                <Input
                  title='Cena od'
                  id='min_price'
                  type='number'
                  className='lodge-input'
                />
                <Input
                  title='Kod pocztowy'
                  id='postal_code'
                  type='text'
                  className='lodge-input'
                />
                <Input
                  title='Telefon'
                  id='phone'
                  type='phone'
                  className='lodge-input'
                />
              </div>
            </div>
            <div className='col-12 lodge-input'>
              <Textarea title='Opis' id='description' className='lodge-input' />
            </div>
            <h5>Typ obiektu</h5>
            <div className='col-12 lodge-facilities mb-5'>
              <Dropdown dictionary={objectTypeDictionary} id='type' />
            </div>
            <h5>Udogodnienia</h5>
            <div className='col-12 lodge-facilities mb-5'>
              <MultiSelect dictionary={facilitiesDictionary} id='facilities' />
            </div>
          </div>
          <div className='button-group'>
            <ActionButton
              type='add'
              confirmMessage='Nowy obiekt zostanie zapisany'
              onClick={async data => {
                await saveHandler({ body: data });
                closeAction();
              }}
            >
              {isEdit ? "Edytuj obiekt" : "Dodaj obiekt"}
            </ActionButton>
            <ActionButton type='back' onClick={() => closeAction()}>
              Anuluj
            </ActionButton>
          </div>
        </FormProvider>
      </Modal>
    </>
  );
};

export default AddLodge;
