import "./Menu.scss";
import Image from "modules/Image";
import { useSidebar } from "hooks/useSidebar";
import { useNavigate, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import PublicationManager from "../DetailsManageObject/ObjectsList/ObjectsDetailsTabs/ObjectDetails/PublicationManager/PublicationManager";

const Menu = ({ login }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const { setSidebar, sidebarData } = useSidebar();
  console.log(sidebarData);
  const { gallery, name: lodgeName, city, address, phone } = sidebarData;

  const backHandler = () => {
    setSidebar({});
    navigate("/zarzadzaj/obiekty");
  };

  const hasPathLogde = /obiekty/.test(location.pathname);

  return (
    <div className='menu-manage-room-module'>
      {login && !lodgeName && hasPathLogde && (
        <>
          <h5>Witaj {login}!</h5>
          <p>Wybierz obiekt, którym chcesz zarządzać lub dodaj nowy.</p>
        </>
      )}
      {lodgeName && (
        <>
          <p>
            Aktualnie zarządzasz:
            <br />
            <strong>{lodgeName}</strong>
          </p>
          <PublicationManager />
          <div className='menu-management'>
            <div className='image-container'>
              <Image src={gallery} alt={`${lodgeName} photo`} />
            </div>

            <p>
              <strong>Miasto:</strong> {city}
              <br />
              <strong>Adres:</strong> {address}
              <br />
              <strong>Tel.:</strong> {phone}
            </p>
          </div>

          <button className='primary' onClick={backHandler}>
            <FontAwesomeIcon icon={faArrowLeft} />
            Wróć do listy obiektów
          </button>
        </>
      )}
    </div>
  );
};

export default Menu;
