import { useState } from "react";
import AddEditRoom from "../AddEditRoom";
import { ActionButton } from "modules/Form";
import { useRooms } from "OdevFetch";

import "./RoomDetails.scss";

const RoomDetails = ({ data, refetch }) => {
  const { type, price_day, amount, id, price_person, price_day_weekend, price_person_weekend, people_count, person } = data;
  const { remove } = useRooms({isLazy: true});

  const [editRoom, setEditRoom] = useState(false);
  const closeAction = () => {
    setEditRoom(false);
    refetch();
  };

  const removeHandler = async () => {
    await remove({id});
    refetch();
  };

  return (
    <div className='room-details-module'>
      <div className='room-details-wrapper'>
        <div className='room-header'>
          <span>{type}</span>
        </div>
        <div className='single-room-wrapper'>
          <div className='single-room'>
            <p>
              Ilość: <span>{amount}</span>
            </p>
            <p>
              Cena za dobę: <span>{price_day}</span>
            </p>
            <p>
              Cena za dobę(weekend): <span>{price_day_weekend}</span>
            </p>
          </div>
          <div className='single-room'>
            <p>
              Ilość osób: <span>{people_count}</span>
            </p>
            <p>
              Cena za osobę: <span>{price_person}</span>
            </p>
            <p>
              Cena za osobę(weekend): <span>{price_person_weekend}</span>
            </p>
          </div>
        </div>
      </div>
      <div className='room-button'>
        <button className='secondary' onClick={() => setEditRoom(true)}>Edytuj</button>
        <ActionButton type="add" className="secondary" onClick={removeHandler}>Usuń</ActionButton>
      </div>
      {editRoom && <AddEditRoom id={data.id} closeAction={closeAction} />}
    </div>
  );
};

export default RoomDetails;
