import Modal from "modules/Modal/Modal";
import { ActionButton, Input, Textarea } from "modules/Form";
import FormProvider from "providers/FormProvider";
import { formConfig } from "./config";
import { useState } from "react";
import "./AskForLodge.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import Image from "modules/Image";
import { useAsks } from "OdevFetch";

const AskForLodge = ({ closeAction, data, formData }) => {
  const { address, gallery, name, min_price, id, city } = data;
  const [isSended, setIsSended] = useState(false);
  const { save } = useAsks({ isLazy: true });
  const galleryImage = `${process.env.REACT_APP_IMAGES_PATH}${id}/${gallery?.[0]?.image_name}`;

  return (
    <>
      <Modal
        className='ask-form-module'
        closeAction={() => {
          closeAction();
        }}
      >
        <div className='row ask-for-lodge-module'>
          <div className='col-12'>
            <div className='row'>
              <div className='col-4 image-form'>
                <Image src={galleryImage} />
              </div>
              <div className='col-8 lodge-informations'>
                <p>Zapytaj o nocleg</p>
                <div className='col-12'>
                  <h4 className='name'>{name}</h4>
                  <div className='lodge-informations-adress'>
                    <span className='adress'>
                      <FontAwesomeIcon icon={faLocationDot} />
                      <span>
                        {address}, {city}
                      </span>
                    </span>
                    <p className='min_price'>Cena od {min_price} zł</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-12 form-container'>
            <FormProvider config={formConfig} payload={formData}>
              {!isSended && (
                <div className='row'>
                  <div className='col-12 row'>
                    <div className='col-12 col-sm-6'>
                      <Input title='Data od' id='start_date' type='date' />
                    </div>
                    <div className='col-12 col-sm-6'>
                      <Input title='Data do' id='finish_date' type='date' />
                    </div>
                    <div className='col-12 col-sm-6 col-lg-4 '>
                      <Input
                        title='Dorośli'
                        id='adult'
                        placeholder='ilość'
                        type='number'
                      />
                    </div>
                    <div className='col-12 col-sm-6 col-lg-4 '>
                      <Input
                        title='Dzieci'
                        id='child'
                        placeholder='ilość'
                        type='number'
                      />
                    </div>
                    <div className='col-12 col-sm-6 col-lg-4 '>
                      <Input
                        title='Pokoje'
                        id='room'
                        placeholder='ilość'
                        type='number'
                      />
                    </div>
                    <div className='col-12'>
                      <Textarea title='Wiadomość' id='message' />
                    </div>
                    <div className='col-12 col-sm-6'>
                      <Input title='Imię i Nazwisko' id='name' />
                    </div>
                    <div className='col-12 col-sm-6'>
                      <Input title='Numer Telefonu' id='phone' type='number' />
                    </div>
                    <div className='col-12'>
                      <Input title='E-mail' id='email' />
                    </div>
                  </div>
                </div>
              )}
              {isSended && (
                <div className='is-sended'>
                  <h5>Twoja wiadomość została wysłana</h5>
                  <h5>
                    <span>Dziękujemy za kontakt</span>
                  </h5>
                  <Image src='/logo.png' alt='Logo' />
                </div>
              )}
              <div className='button-group'>
                {!isSended && (
                  <ActionButton
                    type='add'
                    confirmMessage='Wiadomość zostanie wysłana'
                    onClick={async data => {
                      await save({ body: { ...data, lodge_id: id } });
                      setIsSended(true);
                    }}
                  >
                    Wyślij
                  </ActionButton>
                )}
                <ActionButton type='back' onClick={() => closeAction()}>
                  {isSended ? "Zamknij" : "Anuluj"}
                </ActionButton>
              </div>
            </FormProvider>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AskForLodge;
