import { useContext } from "react";
import { SidebarContext } from "providers/SidebarProvider";

export const useSidebar = () => {
  const context = useContext(SidebarContext);

  if (context === undefined) {
    throw new Error("useLodge was used outside of its Provider");
  }

  const { sidebarData, setSidebarData } = context;

  const setSidebar = data => {
    setSidebarData(data);
  };

  return { ...context, setSidebar, sidebarData };
};
