import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useUsers } from "OdevFetch";
import "./RegisterConfirm.scss";

const RegisterConfirm = () => {
  const navigate = useNavigate();
  const [state, setState] = useState({
    status: "error",
    loading: true,
  });
  const { token } = useParams();
  const { activate } = useUsers({ isLazy: true });

  useEffect(() => {
    const checkActivation = async () => {
      const payload = await activate({ body: { token } });
      if (payload) {
        setState({ loading: false, status: "success" });
      } else {
        setState({ loading: false, status: "error" });
      }
    };
    checkActivation();
  }, []);

  if (state.loading) return <div>Loading</div>;
  return (
    <div className='register-confirm-module'>
      <div>
        {state.status === "success" ? (
          <>
            <p>Twoje konto zostało aktywowane!</p>
            <p>Przejdź na podstronę logowania.</p>
            <span onClick={() => navigate("/login")}>Przejdź</span>
          </>
        ) : (
          <>
            <p>Twój link aktywacyjny wygasł lub nie istnieje.</p>
            <span onClick={() => navigate("/")}>Powrót na stronę główną</span>
          </>
        )}
      </div>
    </div>
  );
};

export default RegisterConfirm;
