import { useState } from "react";
import AddEditRoom from "./AddEditRoom";
import RoomDetails from "./RoomDetails/RoomDetails";
import "./RoomOfferts.scss";
import { useRooms } from "OdevFetch";
import { useParams } from "react-router-dom";

const RoomOfferts = () => {
  const [openModal, setOpenModal] = useState(false);
  const { id } = useParams();
  const { payload, loading, refetch } = useRooms({
    query: { lodge_id: id },
    isSecure: true,
  });

  const addNewRoom = () => {
    setOpenModal(true);
  };

  const refetchAction = () => {
    refetch({ query: { lodge_id: id } });
  };
  const closeAction = () => {
    setOpenModal(false);
    refetchAction();
  };

  if (loading) return <div>Loading</div>;

  return (
    <div className='col-12 room-offers-panel'>
      <div className='room-offers-container'>
        <div className='offers-header'>
          <p>Nazwa domku</p>
          <button className='primary' onClick={() => addNewRoom()}>
            Dodaj pokoj
          </button>
        </div>
        <div className='room-offers-add-container'>
          {payload?.data?.map((item, index) => (
            <RoomDetails data={item} refetch={refetchAction} key={index} />
          ))}
        </div>
      </div>
      {openModal && <AddEditRoom closeAction={closeAction} />}
    </div>
  );
};

export default RoomOfferts;
