import { useContext } from "react";
import { LodgeContext } from "providers/LodgeProvider";

export const useLodgeContext = () => {
  const context = useContext(LodgeContext);

  if (context === undefined) {
    throw new Error("useLodge was used outside of its Provider");
  }

  const {
    lodgeData,
    setLodgeData,
    filtersData,
    setFiltersData,
    setSortData,
    sortData,
  } = context;

  const setLodge = data => {
    setLodgeData(data);
  };

  const setFilters = data => {
    console.log(data);
    setFiltersData(data);
  };

  const setSort = data => {
    setSortData(data);
  };

  return {
    ...context,
    setLodge,
    setFilters,
    filtersData,
    sortData,
    lodgeData,
    setSort,
  };
};
