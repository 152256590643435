import Modal from "modules/Modal/Modal";
import "./VerifyPayment.scss";

const VerifyPayment = ({ onClose }) => {
  return (
    <Modal
      title='Weryfikacja płatności'
      className='verify-payment-module'
      closeAction={() => onClose()}
    >
      Jeśli transakcja przebiegła pomyślnie, zmiany pojawią się na Twoim koncie w ciągu
      kilku minut.
      <br />
      <br />
      Dziękujemy!
    </Modal>
  );
};

export default VerifyPayment;
