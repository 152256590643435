export const BUTTON_TYPE = {
  add: {
    valid: true,
    confirm: true,
    className: "success",
  },
  edit: {
    valid: true,
    confirm: true,
    className: "success",
  },
  clear: {
    valid: false,
    confirm: false,
    className: "clear",
  },
  navigate: {
    valid: false,
    confirm: false,
    className: "primary",
  },
  remove: {
    valid: false,
    confirm: true,
    className: "danger",
  },
  back: {
    valid: false,
    confirm: false,
    className: "warning",
  },
  basic: {
    valid: false,
    confirm: false,
    className: "",
  },
  find: {
    valid: true,
    confirm: false,
    className: "success",
  },
  justAdd: {
    valid: false,
    confirm: true,
    className: "success",
  },
};
