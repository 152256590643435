import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { useUser } from "hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./PhotoImport.scss";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { useParams } from "react-router-dom";
import { useGalleryFile } from "OdevFetch";

const PhotoImport = ({ refetch }) => {
  const { id: lodgingId } = useParams();
  const { userData } = useUser();
  const { save } = useGalleryFile();

  const onDrop = useCallback(async acceptedFiles => {
    const formData = new FormData();

    formData.append("userId", userData.id);
    formData.append("lodgingId", lodgingId);
    acceptedFiles.forEach(file => {
      formData.append("galleryFiles", file);
    });

    await save({ body: formData });
    window.location.reload();
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div className='photo-import-module'>
      <form>
        <div {...getRootProps()}>
          <input {...getInputProps()} id='file' multiple />
          {isDragActive ? (
            <div className='drop-area on-hover'>Upuść tutaj ...</div>
          ) : (
            <div className='drop-area'>
              <FontAwesomeIcon icon={faDownload} />
              Przeciągnij i upuść we wskazanym miejscu lub skorzystaj z przycisku by
              zaimportować pliki z komputera
            </div>
          )}
        </div>
      </form>
    </div>
  );
};

export default PhotoImport;
