import "./DescriptionItem.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

const DescriptionItem = ({ description, address, city, postal_code }) => {
  return (
    <div className='description-item-module'>
      <div className='description-wrapper'>
        <h4>Opis podstawowy</h4>
        <p>{description}</p>
      </div>

      <div className='room-wrapper'>
        <h4>
          Miasto: <span>{city}</span>
        </h4>
        <h4>
          Kod pocztowy: <span>{postal_code}</span>
        </h4>
        <h4>
          Ulica: <span>{address}</span>
        </h4>
      </div>
    </div>
  );
};

export default DescriptionItem;
