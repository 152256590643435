import { useForm } from "hooks";
import { get } from "lodash";

const getItems = (items, selectedKey) => {
  const optionsElements = items.map((item, index) => (
    <option value={item.key} key={index} selected={item.key == selectedKey}>
      {item.value}
    </option>
  ));

  optionsElements.unshift(
    <option value={""} key={null} selected={!selectedKey}>
      (pusty)
    </option>
  );

  return optionsElements;
};

const Dropdown = ({ title, dictionary, id, readOnly }) => {
  const { onChange, data, validationMessage, isSendTry } = useForm();

  return (
    <div className='dropdown'>
      {title && <label>{title}</label>}
      <select
        onChange={e => onChange({ id, value: String(e.target.value) })}
        readOnly={readOnly}
      >
        {getItems(dictionary, data[id])}
      </select>
      {isSendTry && (
        <div className='validationMessage'>{get(validationMessage, id, "")}</div>
      )}
    </div>
  );
};

export default Dropdown;
