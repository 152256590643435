import { BrowserRouter } from "react-router-dom";
import "./App.scss";
import UserProvider from "./providers/UserProvider";
import { Root } from "./Root";
import LodgeProvider from "providers/LodgeProvider";
import moment from "moment";
import "moment/locale/pl";

moment.locale("pl");

function App() {
  return (
    <div>
      <BrowserRouter>
        <UserProvider>
          <LodgeProvider>
            <Root />
          </LodgeProvider>
        </UserProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
