import { useState } from "react";
import "./Loading.scss";

const Loading = () => {
  const [isOpen, setIsOpen] = useState(false);

  document.showLoading = () => {
    setIsOpen(true);
  };

  document.closeLoading = () => {
    setIsOpen(false);
  };

  return (
    isOpen && (
      <div className="loading-overlay">
        <div className="d-flex justify-content-center">
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      </div>

    )
  );
};

export default Loading;
