import React from "react";
import Modal from "modules/Modal/Modal";
import ImageGallery from "react-image-gallery";
import "./GalleryPreview.scss";

const GalleryPreview = ({ closeAction, images, lodgeId, index}) => {

  const imageConfig = images.map(item => ({
    original: `${process.env.REACT_APP_IMAGES_PATH}${lodgeId}/${item.image_name}`,
    thumbnail: `${process.env.REACT_APP_IMAGES_PATH}${lodgeId}/${item.image_name}`,
  }));

  return (
    <>
      <Modal
        className='gallery-preview-module'
        closeAction={() => {
          closeAction();
        }}
      >
        <ImageGallery
          startIndex={index}
          items={imageConfig}
          showThumbnails={false}
        />
      </Modal>
    </>
  );
};

export default GalleryPreview;
