import { useState } from "react";
import "./EmptyPage.scss";
import AddLoge from "modules/AddLodge/AddLodge";
import Image from "modules/Image";

const EmptyPage = ({ refetch }) => {
  const [openModal, setOpenModal] = useState(false);

  const closeAction = () => {
    setOpenModal(false);
    refetch();
  };

  return (
    <div className='empty-page-module'>
      <div className='first-view-manage-room'>
        <div className='first-view-image-box'>
          <Image src='room.jpg' alt='123' />
        </div>
        <h3 className='title'>Witaj w Twoim centrum dowodzenia czy coś!</h3>
        <span className='subtitle'>
          Trochę opis o co chodzi i co tu będzie się działo w ogóle. Lorem ipsum dolor
          sit amet, consectetur adipiscing elit. Proin magna risus, accumsan a tortor
          eget, volutpat feugiat justo. Phasellus varius rhoncus ultricies. Sed
          tristique ex vel viverra imperdiet. Integer nec enim nisl. Sed bibendum
          hendrerit orci, ac scelerisque neque. Nulla eu dui quam. Donec fringilla, nibh
          sed commodo consectetur, turpis nisi pulvinar neque, vel tristique justo justo
          in urna.
        </span>
        <button className='primary' onClick={() => setOpenModal(true)}>
          Dodaj obiekt
        </button>
      </div>
      {openModal && <AddLoge closeAction={closeAction} />}
    </div>
  );
};

export default EmptyPage;
