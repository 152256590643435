import "./Map.scss";
import GeocodingMap from "./GeocodingMap";

const Map = ({location}) => {
  return (
    <div className="map-module">
      <h1>Mapa</h1>
      <GeocodingMap city={location.city} street={location.address.split(" ")[0]} number={location.address.split(" ")[1]} />
    </div>
  );
};

export default Map;
