import React from "react";
import "./CalendarPopup.scss";
import Modal from "modules/Modal/Modal";
import moment from "moment";
import FormProvider from "providers/FormProvider";
import { formConfig } from "./config";
import { ActionButton, DateInput, Input, RadioInputs } from "modules/Form";
import { useCalendar } from "../../OdevFetch";

const CalendarPopup = ({ closeAction, data }) => {
  console.log({ data1: data });
  const { save, remove } = useCalendar({ isLazy: true, isSecure: true });

  const handleDelete = async () => {
    if (data.id) {
      await remove({ id: data.id });
      closeAction();
    }
  };

  const handleSave = async data => {
    await save({
      body: {
        ...data,
        start_date: moment(data.start_date).format(),
        end_date: moment(data.end_date).format(),
      },
    });
    closeAction();
  };

  const title = data.id ? "Edytuj rezerwacje" : "Zarezerwuj lub zablokuj";

  return (
    <Modal
      title={title}
      className='form add-reservation-module'
      closeAction={closeAction}
    >
      <FormProvider config={formConfig} payload={data}>
        <div className='row mt-3'>
          <h6 className='align-self-start mb-0'>Typ blokady</h6>
          <RadioInputs
            id='type'
            config={[
              {
                title: "Dodaj rezerwację",
                value: 1,
              },
              {
                title: "Zablokuj termin",
                value: 2,
              },
            ]}
          />
        </div>
        <div className='row mt-3'>
          <div className='col-6'>
            <DateInput id='start_date' title='Termin od:' />
          </div>
          <div className='col-6'>
            <DateInput id='end_date' title='Termin do:' />
          </div>
        </div>
        <Input id='blockadeDescription' title='Nazwa blokady' className='col-12 mt-3' />
        <div className='col-12 button-group mt-5'>
          <ActionButton type='add' onClick={handleSave}>
            Zapisz
          </ActionButton>
          {data.id && (
            <ActionButton type='remove' onClick={handleDelete}>
              Usuń
            </ActionButton>
          )}
          <ActionButton type='back' onClick={() => closeAction()}>
            Anuluj
          </ActionButton>
        </div>
      </FormProvider>
    </Modal>
  );
};

export default CalendarPopup;
