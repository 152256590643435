import "./Pagination.scss";

const Pagination = ({ settings, onPageChange }) => {
  const { totalPages = 0, currentPage = 1 } = settings || {};

  if (totalPages < 2) {
    return "";
  }

  const handlePageClick = page => {
    if (page < 1 || page > totalPages) return;
    onPageChange(page);
  };

  return (
    <div className='pagination'>
      {currentPage !== 1 ? (
        <button className='navigation' onClick={() => handlePageClick(currentPage - 1)}>
          &laquo; poprzednia strona
        </button>
      ) : (
        ""
      )}
      {[...Array(totalPages || 0)].map((_, idx) => (
        <button
          key={Number(idx)}
          className={Number(idx) + 1 == Number(currentPage) ? "active" : ""}
          onClick={() => handlePageClick(Number(idx) + 1)}
        >
          {Number(idx) + 1}
        </button>
      ))}
      {currentPage !== totalPages ? (
        <button
          className='navigation'
          onClick={() => handlePageClick(Number(currentPage) + 1)}
        >
          następna strona &raquo;
        </button>
      ) : (
        ""
      )}
    </div>
  );
};

export default Pagination;
