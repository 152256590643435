import "./Opinion.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar as faStarSolid } from "@fortawesome/free-solid-svg-icons";

const Opinion = ({ author, stars, publicDate, comment }) => {
  return (
    <div className='opinion-single-module col-12 col-lg-5'>
      <div className='opinion-header'>
        <div>
          <h4>{author}</h4>
          <h5>{publicDate}</h5>
        </div>
        <div className='opinion-rating'>
          {[1, 2, 3, 4, 5].map(item =>
            item > stars ? (
              <span key={item}>
                <FontAwesomeIcon icon={faStarSolid} style={{ color: "#D0D0D0" }} />
              </span>
            ) : (
              <span key={item}>
                <FontAwesomeIcon icon={faStarSolid} />
              </span>
            )
          )}
        </div>
      </div>
      <div className='opinion-description'>
        <p>
         {comment}
        </p>
      </div>
    </div>
  );
};

export default Opinion;
