import { useState } from "react";
import "./SortController.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSort } from "@fortawesome/free-solid-svg-icons";

const SortController = ({ config, sortData, onSetSort }) => {
  const [isOpenSort, setOpenSort] = useState(false);

  const toggleSortDropdown = () => {
    setOpenSort(prev => !prev);
  };

  const handleSortSelection = (type, direction) => {
    onSetSort({ type, direction });
    setOpenSort(false);
  };

  return (
    <div className='sort-controller-module'>
      <FontAwesomeIcon icon={faSort} onClick={toggleSortDropdown} />
      <b onClick={toggleSortDropdown}>sortowanie</b>
      <ul className={`sort-list ${isOpenSort ? "show" : ""}`}>
        {config.map(item => (
          <li
            key={`${item.type}-${item.title}`}
            onClick={() => handleSortSelection(item.type, item.direction)}
          >
            {item.title}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SortController;
