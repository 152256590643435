import { useForm } from "../../../hooks";
import { get } from "lodash";
import { useState } from "react";
import ConfirmPopup from "../ConfirmPopup";
import { BUTTON_TYPE } from "./config";

const ActionButton = ({
  children,
  type,
  onClick,
  confirmMessage = "Czy jesteś pewien?",
  className: additionalClassNames,
}) => {
  const [confirmWindow, setConfirmWindow] = useState(false);
  const { data, isValid, changeIsSendTry, onClear } = useForm();

  const { valid, confirm, className } = get(BUTTON_TYPE, type, BUTTON_TYPE.basic);

  const onValidationHandler = () => {
    changeIsSendTry(true);
  };

  const onClickHandler = () => {
    if ((type == "clear")) {
      return onClear();
    }
    return confirm ? setConfirmWindow(true) : onClick(data);
  };

  return (
    <>
      <button
        className={className + " " + additionalClassNames || ""}
        onClick={() => {
          valid && !isValid ? onValidationHandler() : onClickHandler();
        }}
      >
        {children}
      </button>
      {confirmWindow ? (
        <ConfirmPopup
          message={confirmMessage}
          confirmClick={onClick}
          closeButton={setConfirmWindow}
          data={data}
        />
      ) : null}
    </>
  );
};

export default ActionButton;
