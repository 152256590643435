import React, { useState } from "react";
import "./Register.scss";
import { registerConfig } from "./config";
import { useUsers } from "OdevFetch";
import FormProvider from "providers/FormProvider";
import { Input, ActionButton } from "modules/Form";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const Register = () => {
  const { save } = useUsers({ isLazy: true });
  const navigate = useNavigate();

  const handleRegister = async data => {
    const { repeat_password, ...otherData } = data;
    const isSamePassword = data.password == data.repeat_password;

    if (isSamePassword) {
      await save({
        body: otherData,
      });
      navigate("/login");
    }
  };

  return (
    <div className='register-module'>
      <div className='register-container'>
        <h1 className='register-title'>Zarejestruj się w Rentali</h1>
        <div className='form row'>
          <FormProvider config={registerConfig} className='row'>
            <div className='form-group col-12'>
              <Input title='Login' id='login' type='text' />
            </div>
            <div className='form-group col-12'>
              <Input title='Email' id='email' type='email' />
            </div>
            <div className='form-group col-12'>
              <Input title='Hasło' id='password' type='password' />
            </div>
            <div className='form-group col-12'>
              <Input title='Powtórz hasło' id='repeat_password' type='password' />
            </div>
            <div className='form-buttons col-12'>
              <ActionButton
                type='add'
                className='register-button'
                onClick={data => handleRegister(data)}
              >
                Rejestracja
              </ActionButton>
              <Link to='/login' className='back-to-login-link'>
                Wróć do logowania
              </Link>
            </div>
          </FormProvider>
        </div>
      </div>
    </div>
  );
};

export default Register;
