import { createContext, useState } from "react";

export const LodgeContext = createContext({});

const LodgeProvider = ({ children }) => {
  const [lodgeData, setLodgeData] = useState({});
  const [filtersData, setFiltersData] = useState({
    city: "",
    min_price: "",
    adult: "",
    children: "",
    facilities: [],
    type: [],
  });
  const [sortData, setSortData] = useState({ type: "name", direction: "asc" });

  return (
    <LodgeContext.Provider
      value={{
        lodgeData,
        setLodgeData,
        filtersData,
        setFiltersData,
        setSortData,
        sortData,
      }}
    >
      {children}
    </LodgeContext.Provider>
  );
};

export default LodgeProvider;
