import { useParams, useNavigate } from "react-router-dom";
import { useLodgings, useDictionary } from "OdevFetch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-regular-svg-icons";
import "./ObjectDetails.scss";
import { translate } from "helpers/translate";
import { useState } from "react";
import AddLodge from "modules/AddLodge/AddLodge";
import Image from "modules/Image";
import { ActionButton } from "modules/Form";
import { useSidebar } from "hooks/useSidebar";
import { useEffect } from "react";

const ObjectDetails = () => {
  const { id } = useParams();
  const {
    payload: lodgingsPayload,
    loading: lodgingsLoading,
    refetch,
    remove,
  } = useLodgings({ id });
  const { payload: facilitiesDictionaryPayload, loading: facilitiesDictionaryLoading } =
    useDictionary({
      query: { type: "facilities" },
    });
  const {
    payload: objectTypesDictionaryPayload,
    loading: objectTypesDictionaryLoading,
  } = useDictionary({
    query: { type: "object_type" },
  });
  const { setSidebar } = useSidebar();
  const navigate = useNavigate();

  const [modal, setModal] = useState(false);
  const closeAction = () => {
    setModal(false);
  };

  const removeHandler = async () => {
    await remove({ id: lodgingsPayload?.data?.id });
    navigate("/zarzadzaj/obiekty");
  };

  useEffect(() => {
    setSidebar({
      city: lodgingsPayload?.data.city,
      name: lodgingsPayload?.data.name,
      address: lodgingsPayload?.data.address,
      phone: lodgingsPayload?.data.phone,
      gallery: `${process.env.REACT_APP_IMAGES_PATH}${id}/${
        lodgingsPayload?.data?.gallery.find(item => item?.is_primary)?.image_name
      }`,
      publication_date: lodgingsPayload?.data.publication_date,
    });
  }, [lodgingsLoading]);

  if (lodgingsLoading || facilitiesDictionaryLoading || objectTypesDictionaryLoading)
    return <div>Loading</div>;

  const {
    address,
    city,
    description,
    gallery,
    name,
    phone,
    facilities,
    type,
    postal_code,
  } = lodgingsPayload?.data;

  return (
    <div className='object-details-module'>
      <div className='row'>
        <div className='col-sm-12 text-end'>
          <button className='secondary edit' onClick={() => setModal(true)}>
            Edytuj
          </button>
          <ActionButton type='add' className='secondary' onClick={removeHandler}>
            Usuń obiekt
          </ActionButton>
        </div>
        <div className='col-sm-12 col-lg-4 col-xl-3'>
          <Image
            src={`${process.env.REACT_APP_IMAGES_PATH}${
              gallery.find(item => item?.is_primary)?.path
            }`}
          />
        </div>
        <div className='col-sm-12 col-lg-8 col-xl-9'>
          <div className='row description'>
            <h2>{name}</h2>
            <div>
              <b>Miasto: </b>
              {city}
              <br />

              <b>Adres: </b>
              {address}
              <br />

              <b>Telefon: </b>
              {phone}
              <br />
              <b>Kod pocztowy: </b>
              {postal_code}
              <br />
              <b>Typ obiektu: </b>
              {translate(objectTypesDictionaryPayload.data, type)}
            </div>
          </div>
        </div>
      </div>
      <div className='row'>
        <h3>Opis</h3>
        <p>{description}</p>
      </div>

      <div className='row '>
        <h3>Udogodnienia</h3>
      </div>
      <div className='row'>
        {facilities
          .filter(item => item.length > 0)
          .map((item, index) => (
            <div className='col-12 col-md-6 col-xl-4 facilities-item' key={index}>
              <FontAwesomeIcon icon={faCheckCircle} key={index} />
              {translate(facilitiesDictionaryPayload.data, item)}
            </div>
          ))}
      </div>
      {modal && (
        <AddLodge
          closeAction={closeAction}
          data={lodgingsPayload.data}
          refetch={refetch}
        />
      )}
    </div>
  );
};

export default ObjectDetails;
