import "./FiltersBar.scss";
import { faAnglesDown, faAnglesUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { filtersBarConfig } from "./config";
import { Input, MultiSelect, ActionButton, Dropdown } from "modules/Form";
import FormProvider from "providers/FormProvider";
import { useDictionary } from "OdevFetch";
import { useLodgeContext } from "hooks";

const FiltersBar = () => {
  const [isFiltersShown, setIsFiltersShown] = useState(false);
  const { payload, loading } = useDictionary();
  const { setFilters, filtersData } = useLodgeContext();
  const [formVersion, setFormVersion] = useState(1);

  useEffect(() => {
    setFormVersion(formVersion + 1);
  }, [filtersData]);

  if (loading) return <div>Loading...</div>;

  const searchHandler = data => {
    setFilters({ ...filtersData, ...data });
  };

  const facilitiesDictionary = payload?.data?.filter(
    item => item.type === "FACILITIES"
  );
  const objectTypeDictionary = payload?.data?.filter(
    item => item.type === "OBJECT_TYPE"
  );

  return (
    <div className='filters-bar' key={formVersion}>
      <div
        className='filters-hide-selector'
        onClick={() => setIsFiltersShown(!isFiltersShown)}
      >
        {isFiltersShown ? (
          <>
            <p>Schowaj filtry</p>
            <FontAwesomeIcon icon={faAnglesUp} />
          </>
        ) : (
          <>
            <p>Pokaż filtry</p>
            <FontAwesomeIcon icon={faAnglesDown} />
          </>
        )}
      </div>
      <FormProvider config={filtersBarConfig} payload={filtersData}>
        <div
          className={`filters-bar-content ${!isFiltersShown ? "hide-filters-bar" : ""}`}
        >
          <div className='filters-bar-header'>
            <h4>FILTRY </h4>
          </div>
          <div className='filters-bar-object-type'>
            <h5>Rodzaj obiektu</h5>
            <Dropdown dictionary={objectTypeDictionary} id='type' />
            {/* <MultiSelect id='type' dictionary={objectTypeDictionary} /> */}
          </div>
          <div className='filters-bar-price'>
            <h5>Cena</h5>
            <div className='price-input-container'>
              <Input id='min_price' placeholder='Od' className='col-5' />
              -
              <Input id='max_price' placeholder='Do' className='col-5' />
            </div>
          </div>
          <div className='filters-bar-facilities'>
            <h5>Udogodnienia</h5>
            <MultiSelect id='facilities' dictionary={facilitiesDictionary} />
          </div>
          <div>
            <ActionButton className='primary' onClick={data => searchHandler(data)}>
              Filtruj
            </ActionButton>
          </div>
        </div>
      </FormProvider>
    </div>
  );
};

export default FiltersBar;
