import { useForm } from "hooks";
import { get } from "lodash";

const Textarea = ({ title, id, accessor, placeholder }) => {
  const { onChange, data, validationMessage, isSendTry } = useForm();

  return (
    <div className="input">
      <label>{title}</label>
      <textarea
        value={accessor ? get(data, accessor) : get(data, id)}
        onChange={(event) => onChange({ id: id, value: event.target.value })}
        placeholder={placeholder}
      />
      {isSendTry && <div className="validationMessage">{get(validationMessage, id, "")}</div>}
    </div>
  );
};

export default Textarea;
