export const roomLinksConfig = [
  {
    title: "Opis",
    path: "opis",
  },
  {
    title: "Galeria",
    path: "galeria",
  },
  {
    title: "Opinie",
    path: "opinie",
  },
  {
    title: "Mapa",
    path: "mapa",
  },
  {
    title: "Cennik",
    path: "cennik",
  },
  // {
  //   title: "Kalendarz",
  //   path: "kalendarz",
  // },
  {
    title: "Udogodnienia",
    path: "udogodnienia",
  },
];
