const ContactCustomerService = () => {
  return (
    <div className='contact-customer-service'>
      <div className='contact-customer-wrapper'>
        <div className='contact-customer-header'>
          <h1>Biuro Obsługi Klienta</h1>
        </div>
        <div className='contact-customer-ask'>
          <h2>
            Jeśli masz jakieś pytania lub potrzebujesz wsparcia, skontaktuj się z naszym{" "}
            <strong>Biurem Obsługi Klienta</strong>
          </h2>
          <p>Połączenie jest płatne wg stawki operatora</p>
        </div>
        <div className='contact-customer-cards'>
          <div className='contact-customer-card'>
            <h2>Dane operatora portalu - 1v0</h2>
            <h4>
              ul.Kościuszki 53
              <br />
              88-200 Radziejów
              <br />
              NIP: PL 8891027736
              <br />
              REGON: 910954258
            </h4>
            <h4>
              tel. <a href='tel:+48 534 703 001'>+48 534 703 001</a>
            </h4>
            <h4>
              e-mail: <a href='mailto:1v0@1v0.pl'>1v0@1v0.pl</a>
            </h4>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactCustomerService;
