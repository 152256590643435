const ConfirmPopup = ({ message, confirmClick, closeButton, data }) => {
  const onConfirmHandler = () => {
    closeButton(false);
    confirmClick(data);
  };

  return (
    <div className='confirmPopupOverlay'>
      <div className='confirmPopupBox'>
        <h5 className=' mb-4'>{message}</h5>
        <div className='confirm-popup-box-buttons'>
          <button className='confirm' onClick={() => onConfirmHandler()}>
            Tak
          </button>
          <button className='confirm-back' onClick={() => closeButton(false)}>
            Nie
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmPopup;
