import { VALIDATION_SCHEMA } from "modules/Form/validationSchema";

export const opinionConfig = [
  {
    id: "id",
    default: undefined,
  },
  {
    id: "author",
    default: "",
    validation: [...VALIDATION_SCHEMA.name],
  },
  {
    id: "comment",
    default: "",
    validation: [...VALIDATION_SCHEMA.comment],
  },
];
