import "../ForgotPassword/ForgotPassword.scss";
import { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useUsers } from "OdevFetch";

const ResetPassword = () => {
  const { token } = useParams();
  const navigate = useNavigate();

  const { setPassword: setNewPassword } = useUsers({ isLazy: true });
  const [password, setPassword] = useState("");
  const [passwordRepeat, setPasswordRepeat] = useState("");
  const [error, setError] = useState(false);

  const submitHandler = async e => {
    e.preventDefault();
    if (password !== passwordRepeat) {
      setError(true);
    } else {
      await setNewPassword({ body: { token, password } });
      navigate("/login");
    }
  };

  return (
    <div className='forgot-password-module'>
      <div className='forgot-password-container'>
        <h1 className='forgot-password-title'>Zresetuj hasło</h1>
        {error && <p>Hasła muszą być takie same</p>}
        <form>
          <div className='form-group'>
            <label htmlFor='password'>Podaj nowe hasło</label>
            <input
              type='password'
              id='password'
              value={password}
              onChange={e => setPassword(e.target.value)}
              required
            />
          </div>
          <div className='form-group'>
            <label htmlFor='passwordRepeat'>Powtórz nowe hasło</label>
            <input
              type='password'
              id='passwordRepeat'
              value={passwordRepeat}
              onChange={e => setPasswordRepeat(e.target.value)}
              required
            />
          </div>
          <div className='form-buttons'>
            <button className='primary' onClick={e => submitHandler(e)}>
              Resetuj hasło
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;
