import { Navigate, Route, Routes } from "react-router-dom";
import Navbar from "./modules/Navbar/Navbar";
import HomePage from "./pages/HomePage/HomePage";
import Room from "./pages/Room/Room";
import Accommodation from "./pages/Accommodation/Accommodation";
import Login from "./pages/Login/Login";
import Footer from "./modules/Footer/Footer";
import ForgotPassword from "./pages/Login/ForgotPassword/ForgotPassword";
import ManageObjects from "./pages/ManageObjects/ManageObjects";
import { useUser } from "hooks";
import { useEffect } from "react";
import Alert from "modules/Alert/Alert";
import Register from "pages/Login/Register";
import RegisterConfirm from "pages/Login/RegisterConfirm/RegisterConfirm";
import SidebarProvider from "providers/SidebarProvider";
import ResetPassword from "pages/Login/ResetPassword/ResetPassword";
import Contact from "pages/Contact";
import PricingAdv from "pages/PricingAdv/PricingAdv";
import Loading from "./modules/Loading/Loading";
import CookieConsent from "./modules/CookieConsent/CookieConsent";

export const Root = () => {
  const { isLoggedIn } = useUser();

  useEffect(() => {
    isLoggedIn();
  }, []);

  return (
    <div>
      <Navbar />
      <div className='app-container'>
        <Routes>
          <Route exact path='/noclegi' element={<Accommodation />} />
          <Route exact path='/nocleg/:id/*' element={<Room />} />
          <Route exact path='/login' element={<Login />} />
          <Route exact path='/register' element={<Register />} />
          <Route exact path='/activate/:token' element={<RegisterConfirm />} />
          <Route exact path='/reset-password' element={<ForgotPassword />} />
          <Route exact path='/cennik' element={<PricingAdv />} />
          <Route exact path='/kontakt' element={<Contact />} />
          <Route
            exact
            path='/zarzadzaj/*'
            element={
              <SidebarProvider>
                <ManageObjects />
              </SidebarProvider>
            }
          />
          <Route exact path='' element={<HomePage />} />
          <Route path='*' element={<Navigate to='/' replace />} />
          <Route exact path='/reset-password/:token' element={<ResetPassword />} />
        </Routes>
      </div>
      <Footer />
      <Alert />
      <Loading />

      <CookieConsent />
    </div>
  );
};
