import "./AddOpinion.scss";
import Modal from "modules/Modal/Modal";
import FormProvider from "providers/FormProvider";
import { opinionConfig } from "./config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { ActionButton, Input, Textarea } from "modules/Form";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useReviews } from "OdevFetch";

const AddOpinion = ({ closeHandler }) => {
  const [stars, setStars] = useState(1);
  const { id: lodgeId } = useParams();
  const { save } = useReviews({
    isLazy: true,
  });

  const submitHandler = data => {
    save({
      body: {
        ...data,
        lodging_id: lodgeId,
        rating: stars,
      },
    });
    closeHandler();
  };

  return (
    <Modal
      title={"Dodaj opinię"}
      className='add-opinion-module'
      closeAction={() => {
        closeHandler();
      }}
    >
      <FormProvider config={opinionConfig}>
        {" "}
        <div className='row'>
          <div className='row'>
            <Input title='Autor' id='author' className='col-12 col-md-6 author' />
            <div className='col-12 col-md-5 rating'>
              <h3>Ocena</h3>
              <div className='stars'>
                {[1, 2, 3, 4, 5].map(star =>
                  star <= stars ? (
                    <span key={star} onClick={() => setStars(star)}>
                      <FontAwesomeIcon icon={faStar} />
                    </span>
                  ) : (
                    <span key={star} className='grey' onClick={() => setStars(star)}>
                      <FontAwesomeIcon icon={faStar} />
                    </span>
                  )
                )}
              </div>
            </div>
          </div>
          <div className='pt-3'>
            <Textarea title='Opinia' id='comment' />
          </div>
          <div className='pt-3 button-wrapper'>
            <ActionButton
              type='add'
              className='primary'
              onClick={data => submitHandler(data)}
            >
              Prześlij
            </ActionButton>
            <button className='secondary' onClick={() => closeHandler()}>
              Anuluj
            </button>
          </div>
        </div>
      </FormProvider>
    </Modal>
  );
};

export default AddOpinion;
