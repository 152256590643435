import { VALIDATION_SCHEMA } from "modules/Form/validationSchema";

export const invoiceFormConfig = [
  {
    id: "company_name",
    default: "",
    validation: [...VALIDATION_SCHEMA.name],
  },
  {
    id: "nip",
    default: "",
    validation: [...VALIDATION_SCHEMA.nip],
  },
  {
    id: "address",
    default: "",
    validation: [...VALIDATION_SCHEMA.name],
  },
  {
    id: "city",
    default: "",
    validation: [...VALIDATION_SCHEMA.city],
  },
  {
    id: "postal_code",
    default: "",
    validation: [...VALIDATION_SCHEMA.postCode],
  },
  {
    id: "country",
    default: "",
    validation: [...VALIDATION_SCHEMA.country],
  },
];
