import { Route, Routes } from "react-router-dom";
import Menu from "./Menu/Menu";
import ObjectsList from "./DetailsManageObject/ObjectsList/ObjectsList";
import Calendar from "pages/Room/RoomDescription/Calendar/Calendar";
import Points from "./DetailsManageObject/ObjectsList/ObjectsDetailsTabs/Points/Points";
import "./ManageObjects.scss";
import ObjectsDetailsTabs from "./DetailsManageObject/ObjectsList/ObjectsDetailsTabs";
import { useUser, useLodgeContext } from "hooks";
import { useLodgings } from "OdevFetch";
import EmptyPage from "./EmptyPage/EmptyPage";
import Account from "pages/Account/Account";
import { useEffect } from "react";

const ManageRoom = () => {
  const { userData } = useUser();
  const { payload, loading, refetch } = useLodgings({
    isLazy: !userData.id,
    id: userData.id,
  });
  const { setLodge } = useLodgeContext();

  useEffect(() => {
    if (payload?.data) {
      setLodge(payload.data);
    }
  }, []);

  if (loading) return <div>Loading</div>;

  return (
    <div className='manage-objects-module container'>
      <div className='row'>
        <div className='objects-side-menu col-12 col-md-3'>
          <Menu login={userData.login} />
        </div>

        <div className='manage-objects-head-details col-12 col-md-9'>
          {loading ? (
            <EmptyPage refetch={refetch} ownerId={userData.id} />
          ) : (
            <Routes>
              <Route exact path='/obiekty' element={<ObjectsList />} />
              <Route exact path='/kalendarz' element={<Calendar />} />
              <Route exact path='/punkty' element={<Points />} />
              <Route exact path='/obiekty/:id' element={<ObjectsDetailsTabs />} />
              <Route exact path='/konto' element={<Account />} />
            </Routes>
          )}
        </div>
      </div>
    </div>
  );
};

export default ManageRoom;
