import { VALIDATION_SCHEMA } from "modules/Form/validationSchema";

export const formConfig = [
  {
    id: "id",
    default: undefined,
  },
  {
    id: "type",
    default: "",
    validation: [...VALIDATION_SCHEMA.name],
  },
  {
    id: "people_count",
    default: "",
    validation: [...VALIDATION_SCHEMA.roomValues],
  },
  {
    id: "price_day",
    default: "",
    validation: [...VALIDATION_SCHEMA.roomValues],
  },
  {
    id: "price_person",
    default: "",
    validation: [...VALIDATION_SCHEMA.roomValues],
  },
  {
    id: "amount",
    default: "1",
    validation: [...VALIDATION_SCHEMA.roomValues],
  },
  {
    id: "is_weekend_price",
    default: 0,
  },
  {
    id: "price_person_weekend",
    default: null,
    // validation: [...VALIDATION_SCHEMA.roomValues],
  },
  {
    id: "price_day_weekend",
    default: null,
    // validation: [...VALIDATION_SCHEMA.roomValues],
  },
];
