import "./PricingAdv.scss";
import { useUser } from "hooks/useUser";
import { useNavigate } from "react-router-dom";

const PricingAdv = () => {
  const navigate = useNavigate();
  const { isLogged } = useUser();

  return (
    <div className='pricing-list-page'>
      <div className='container'>
        <h3>Cennik usług</h3>

        <article className='row'>
          <div className='col-12 col-md-5 col-lg-3'>
            <img className='pricing-dog' src='pricing-dog.jpg' alt='pricing-dog' />
          </div>
          <div className='col-12 col-md-7 col-lg-9 description'>
            <div className='card'>
              <h5>Model Abonamentowy</h5>
              <p>
                Dla wszystkich ceniących stabilność i bezpośredni kontakt z klientem
                proponujemy model abonamentowy. Umożliwia on aktywne zarządzanie
                widocznością swojego obiektu w serwisie.
              </p>
              <h6>KORZYŚCI:</h6>
              <p>
                Stałość opłat
                <br />
                Płacisz stałą kwotę, niezależnie od liczby rezerwacji. <br />
                Elastyczne rozwiązanie
                <br />U nas możesz się czuć komfortowo. Sam wybierz opcję abonamentu i
                określ czas jego trwania.
              </p>
              Wybierz najkorzystniejszą ofertę dla siebie. Wpis na portalu na:
              <br />
              <div>
                <b>3 miesiące</b> - <strong>99 zł brutto</strong>
              </div>
              <div>
                <b>6 miesięcy</b> - <strong>199 zł brutto</strong>
              </div>
              <div>
                <b>12 miesięcy</b> - <strong>399 zł brutto</strong>
              </div>
            </div>
          </div>
        </article>

        <article className='row'>
          <div className='col-12 col-md-5 col-lg-3 order-md-2'>
            <img className='pricing-car' src='pricing-car.jpg' alt='pricing-car' />
          </div>

          <div className='col-12 col-md-7 col-lg-9 order-md-1 description'>
            <div className='card'>
              <h5>W serwisie Rentali.pl występują punkty pozycjonujące.</h5>
              <p>
                W ramach usługi pozycjonowania można dokupić punkty pozycjonujące, aby
                podwyższyć swoją pozycję na liście wyszukiwania. Wówczas obiekt z
                większą ilością punktów wyświetla się wyżej w stosunku do obiektów z
                mniejszą ilością punktów. Obiekty z tą samą ilością punktów wyświetlają
                się w kolejności losowej między sobą.
              </p>
              <p>
                Koszt 1 punktu pozycjonującego wynosi <b>1zł netto + VAT 23%</b>.
              </p>
              <p>
                Punkty przyznawane są na okres <b>1 miesiąca</b>. Po upływie czasu
                ważności punktów, zostaną one odjęte z puli punktów pozycjonujących
                widniejącej na Koncie Użytkownika.
              </p>
              <p>
                W każdej chwili można wykupić dodatkowe punkty pozycjonujące, aby
                podwyższyć swoją pozycję.
              </p>
              <p>
                Zakup punktów pozycjonujących możliwy jest poprzez panel administracyjny
                poprzez płatność online lub przelewem na konto.
              </p>
            </div>
          </div>
        </article>

        <article className='row'>
          <div className='col-12 col-md-5 col-lg-3'>
            <img className='pricing-dron' src='pricing-dron.jpg' alt='pricing-dron' />
          </div>
          <div className='col-12 col-md-7 col-lg-9 description'>
            <div className='card'>
              <h5>Sesja fotograficzna i Wirtualny Spacer</h5>
              <p>
                Usługę w postaci sesji fotograficznej filmu video czy wirtualnego
                Spaceru należy zamawiać za pośrednictwem panelu administracyjnego.
              </p>

              <p>
                Sesja fotograficzna/video i Wirtualny Spacer obejmują jeden przyjazd
                fotografa i wykonanie:
                <br />
                serii zdjęć, z których do Serwisu zostanie wybranych{" "}
                <b>maksymalnie 15 sztuk</b> (sesja fotograficzna),
                <br />
                serii panoram Lokalu, z których zostanie wybranych maksymalnie{" "}
                <b>7 sztuk</b> i połączonych w wirtualny spacer (Wirtualny Spacer 360°).
                <br />
                60 minut nagrfań video kamera oraz dronem.
                <br />
                Usługi sesja fotograficzna i Wirtualny Spacer wykonywane są według
                kolejności zgłoszeń Użytkowników.
                <br />
              </p>

              <p>
                Sesje fotograficzne/video/wirtualny spacer można zamawiać poprzez
                kontakt z administratorem poprzez kontakt mailowy na{" "}
                <a href='mailto(1v@1v0.pl)'>1v@1v0.pl</a>
              </p>
            </div>
          </div>
        </article>

        <article className='row'>
          <div className='col-12 col-md-5 col-lg-3 order-md-2'>
            <img
              className='pricing-laptop'
              src='pricing-laptop.jpg'
              alt='pricing-laptop'
            />
          </div>
          <div className='col-12 col-md-7 col-lg-9 order-md-1 description'>
            <div className='card'>
              <h5>Usługa Strony WWW</h5>
              <p>
                W ramach usługi strony www Rentali.pl udostępnia Aktywnym Użytkownikom
                subdomenę w ramach „rentali.pl”, np. <b>nazwalokalu.rentali.pl</b>{" "}
                („Usługa Strony WWW”). Na zamówionej przez użytkownika stronie www będą
                znajdować się identyczne dane jak podane w serwisie Rentali.pl
              </p>
              <p>
                Użytkownik może zamówić stronę www na własnej domenie z pełnym
                przeniesieniem własności poprzez kontakt z administratorem poprzez
                kontakt mailowy na <a href='mailto(1v@1v0.pl)'>1v@1v0.pl</a>
              </p>
            </div>
          </div>
        </article>

        <button
          className='primary'
          onClick={() => navigate(isLogged ? "/zarzadzaj/obiekty" : "/login")}
        >
          Opublikuj ogłoszenie
        </button>
      </div>
    </div>
  );
};

export default PricingAdv;
