import { useState } from "react";
import Ad from "../../../modules/Ad/Ad";
import Lodge from "./Lodge/Lodge";
import { useLodgings } from "../../../OdevFetch";
import "./AccommodationList.scss";
import { useLodgeContext } from "hooks/useLodge";
import { useEffect } from "react";
import { SORT_LIST_CONFIG } from "./config";
import SortController from "modules/SortController/SortController";
import Pagination from "modules/Pagination/Pagination";

const AccommodationList = () => {
  const { filtersData, setSortData, sortData } = useLodgeContext();
  const [currentPage, setCurrentPage] = useState(1);

  const { payload, loading, refetch } = useLodgings({
    query: {
      ...filtersData,
      sort_type: sortData.type,
      sort_direction: sortData.direction,
      page: currentPage,
    },
  });

  useEffect(() => {
    refetch();
  }, [currentPage]);

  useEffect(() => {
    if (currentPage !== 1) {
      setCurrentPage(1);
    } else {
      refetch();
    }
  }, [filtersData, sortData]);

  if (loading) return <div>Loading</div>;

  return (
    <div className='accommodation-list-module row'>
      <div className='col-12 d-flex justify-content-between accommodation-list-wrapper'>
        <div className='accommodations'>
          <b>NOCLEGI</b>
        </div>
        <SortController
          config={SORT_LIST_CONFIG}
          sortData={sortData}
          onSetSort={setSortData}
        />
      </div>
      {payload?.data.length > 0 ? (
        payload?.data.map((item, index) => (
          <div key={index} className='col-12'>
            <Lodge data={item} />
            {(index + 1) % 3 === 0 && <Ad />}
          </div>
        ))
      ) : (
        <div className='col-12'>
          <div className='lodge-error'>
            <div className='error-content'>
              <h4>
                UPSSS...!
                <br />
                Nie znaleziono obiektów.
              </h4>
              <p>
                Nie znaleziono żadnych wyników dla wybranych filtrów. Być może słowo
                zawiera błąd lub nie posiadamy w bazie obiektów, które spełniają
                wszystkie wymagane przez Ciebie kryteria.
              </p>
            </div>
          </div>
        </div>
      )}
      <Pagination settings={payload?.pagination} onPageChange={setCurrentPage} />
    </div>
  );
};

export default AccommodationList;
