import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import "./SingleObject.scss";
import { useNavigate } from "react-router";
import Image from "modules/Image";
import moment from "moment";

const SingleObject = ({ data }) => {
  const navigate = useNavigate();
  const {
    gallery,
    name,
    city,
    address,
    id,
    rooms,
    phone,
    reviews,
    points,
    publication_date,
  } = data;
  const galleryImage = `${process.env.REACT_APP_IMAGES_PATH}${id}/${
    gallery.find(item => item?.is_primary)?.image_name
  }`;
  const roomAmount = rooms
    ?.map(room => room.amount)
    ?.reduce((accumulator, currentVal) => accumulator + currentVal, 0);

  const navigateHandler = () => {
    navigate(`${id}`);
  };

  const rating = reviews?.length
    ? (
        reviews.reduce((sum, review) => sum + review.rating, 0) / reviews.length
      ).toFixed(2)
    : "Brak";

  let sumPoints = 0;
  points.forEach(point => (sumPoints += point.amount));

  const pointsAmount = points.length > 0 ? points[0].amount : 0;

  return (
    <div className='single-object-module col-12'>
      <div className='single-object-wrapper row'>
        <div className='image-placeholder col-12 col-md-2'>
          <Image src={galleryImage} />
        </div>
        <div className='city col-12 col-md-3'>
          {publication_date && moment(publication_date).isBefore(moment()) && (
            <h3 className='publication-indicator'>Nieopublikowany</h3>
          )}
          <h4>{name}</h4>
          <h5>
            <FontAwesomeIcon icon={faLocationDot} />
            {city}, {address}
          </h5>
        </div>
        <div className='rooms col-2 col-md-2'>
          <h2>{roomAmount}</h2>
          <h3>Pokoi</h3>
        </div>
        <div className='rooms col-2 col-md-1'>
          <h2>{sumPoints}</h2>
          <h3>Punkty</h3>
        </div>
        <div className='rating col-2 col-md-2'>
          <h2>{rating}</h2>
          <h3>Ocena</h3>
        </div>
        <div className='button-container col-12 col-xl-2'>
          <button className='primary' onClick={navigateHandler}>
            Przeglądaj
          </button>
        </div>
      </div>
    </div>
  );
};

export default SingleObject;
