import { useForm } from "hooks";
import { get } from "lodash";
import "./RadioInputs.scss";

const RadioInputs = ({ title, config, id }) => {
  const { onChange, data, validationMessage, isSendTry } = useForm();

  return (
    <div className='radio-inputs-module'>
      {title && <label>{title}</label>}

      {config.map((item, index) => (
        <div
          key={index}
          className='radio-item mt-2'
          onClick={e => onChange({ id, value: item.value })}
        >
          <div className={`radio-button ${item.value === data[id] ? "selected" : ""}`}>
            <div className='circle'></div>
          </div>
          {item.title}
        </div>
      ))}

      {isSendTry && (
        <div className='validationMessage'>{get(validationMessage, id, "")}</div>
      )}
    </div>
  );
};

export default RadioInputs;
