import "./Calendar.scss";
import CalendarNav from "./CalendarNav/CalendarNav";
import { useEffect, useState } from "react";
import CalendarBody from "./CalendarBody/CalendarBody";
import moment from "moment";

const Calendar = () => {
  const [calendarSize, setCalendarSize] = useState(14);
  const [firstDate, setFirstDate] = useState(moment().startOf("day").format());

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;

      if (screenWidth >= 1400 && calendarSize != 12) {
        setCalendarSize(12);
      } else if (screenWidth >= 1200 && calendarSize != 10) {
        setCalendarSize(10);
      } else if (screenWidth < 1200 && calendarSize != 8) {
        setCalendarSize(8);
      }
    };
    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className='calendar-module'>
      <h4>Kalendarz</h4>
      <CalendarNav
        firstDate={firstDate}
        onSetFirstDate={setFirstDate}
        size={calendarSize}
      />
      <CalendarBody firstDate={firstDate} size={calendarSize} />
    </div>
  );
};

export default Calendar;
