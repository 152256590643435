import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faYoutube, faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons";

export const footerLinks = [
  {
    id: 1,
    value: <NavLink to='/kontakt'>Kontakt</NavLink>,
  },
  {
    id: 2,
    value: <NavLink to='/cennik'>Cennik/Reklama</NavLink>,
  },
  {
    id: 3,
    value: (
      <a href='/Polityka_Prywatnosci_rentali.pl.pdf' target='_blank' rel='noreferrer'>
        Polityka prywatności
      </a>
    ),
  },
  {
    id: 4,
    value: (
      <a href='/Regulamin_rentali.pl.pdf' target='_blank' rel='noreferrer'>
        Regulamin
      </a>
    ),
  },
];

export const footerSocialMedia = [
  {
    id: 1,
    icon: (
      <span>
        <FontAwesomeIcon icon={faFacebook} />
      </span>
    ),
    href: "https://www.facebook.com/1v0digitalmarketing",
  },
  {
    id: 2,
    icon: (
      <span>
        <FontAwesomeIcon icon={faYoutube} />
      </span>
    ),
    href: "https://www.youtube.com/@1v0pl",
  },
  {
    id: 3,
    icon: (
      <span>
        <FontAwesomeIcon icon={faInstagram} />
      </span>
    ),
    href: "https://www.instagram.com/1v0_pl/",
  },
];
