import {
  translateDay,
  SHORT_MONTH_NAMES,
  DATE_FORMAT,
  isDateBetween,
} from "helpers/date";
import moment from "moment";

const CalendarRow = ({
  room,
  days,
  onAddReservation,
  reservations,
  onEditReservation,
  size,
  firstDate,
}) => {
  // Oblicz endDate na podstawie size i firstDate
  const endDate = moment(firstDate)
    .add(size - 1, "days")
    .format();

  const handleAddReservation = day => {
    console.log({ day });
    const startDate = moment(day).format(DATE_FORMAT);
    onAddReservation({ room, startDate });
  };

  const handleEditReservation = reservation => {
    onEditReservation(reservation);
  };

  function getDaysDifference(reservation, minDateWithHour, maxDate) {
    let additionalDay = 1;
    let startDate = moment(reservation.start_date).startOf("day");
    let endDate = moment(reservation.end_date).startOf("day");

    const minDate = moment(minDateWithHour).startOf("day");

    console.log(minDate, endDate.format());

    if (startDate.isBefore(minDate)) {
      // additionalDay = 2;
      startDate = moment(minDate).startOf("day");
    }

    if (endDate.isAfter(maxDate)) {
      endDate = moment(maxDate).startOf("day");
    }

    return endDate.diff(startDate, "days") + additionalDay;
  }

  const generateCalendarDivs = () => {
    let divs = [];
    let currentDate = moment(firstDate).startOf("day");

    while (currentDate.isBefore(endDate) || currentDate.isSame(endDate)) {
      const reservation = reservations.find(reservation => {
        const startDate = moment(reservation.start_date).startOf("day");
        const endDate = moment(reservation.end_date).startOf("day");

        return (
          currentDate.isSameOrAfter(startDate) && currentDate.isSameOrBefore(endDate)
        );
      });

      if (reservation) {
        const daysDifference = getDaysDifference(reservation, firstDate, endDate);
        const totalWidth = daysDifference * 47 - 2;

        divs.push(
          <div
            key={currentDate}
            className={`block ${reservation.type == 1 ? "reservation" : ""}`}
            style={{ width: totalWidth, marginRight: "2px" }}
            onClick={() => handleEditReservation(reservation)}
          >
            <h6>
              {moment(reservation.start_date).startOf("day").format("DD.MMM")}-
              {moment(reservation.end_date).startOf("day").format("DD.MMM")}
            </h6>
            {reservation.blockadeDescription}
          </div>
        );

        currentDate.add(daysDifference, "days");
      } else {
        //fix for while context
        const currentDateValue = currentDate.format();
        divs.push(
          <div
            key={currentDate.format()}
            className='cell day'
            onClick={() => handleAddReservation(currentDateValue)}
          >
            {currentDate.format("D MMM")}
          </div>
        );
        // console.log("before", currentDate.format());
        currentDate.add(24, "hours");
        // console.log("after", currentDate.format());
      }
    }

    return divs;
  };

  return (
    <div className='calendar-row-module calendar-row'>
      <div className='title'>{room.name}</div>
      <div className='days-container'>{generateCalendarDivs()}</div>
    </div>
  );
};

export default CalendarRow;
