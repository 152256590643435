import Card from "../Card/Card";
import "./Tabs.scss";
import { useState } from "react";

const Tabs = ({ config, externalState }) => {
  const isExternalState = !!externalState?.setSelectedTab;
  let [selectedTab, setTab] = useState(config[0].id);
  if (isExternalState) {
    selectedTab = externalState.selectedTab;
    setTab = externalState.setSelectedTab;
  }
  const selectedConfig = config.find(element => element.id === selectedTab);

  return (
    <div className='tabs'>
      <ul className='tab-navigation'>
        {config.map((item, index) => (
          <li
            key={index}
            className={item.id === selectedTab ? "active" : ""}
            onClick={() => setTab(item.id)}
          >
            <div className='tab'>{item.title}</div>
          </li>
        ))}
      </ul>
      <Card className='tabs-content'>{selectedConfig.render()}</Card>
    </div>
  );
};

export default Tabs;
