import { VALIDATION_SCHEMA } from "modules/Form/validationSchema";

export const accountConfig = [
  {
    id: "first_name",
    default: "",
    validation: [...VALIDATION_SCHEMA.name],
  },
  {
    id: "last_name",
    default: "",
    validation: [...VALIDATION_SCHEMA.name],
  },
  {
    id: "email",
    default: "",
    validation: [...VALIDATION_SCHEMA.email],

  },
  {
    id: "password",
    default: "",
  },
  {
    id: "old_password",
    default: "",
  },
  {
    id: "password_repeat",
    default: "",
  },
];