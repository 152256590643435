import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./FiltersMain.scss";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import Card from "../Card/Card";
import FormProvider from "providers/FormProvider";
import { filterConfig } from "./config";
import { ActionButton, Input } from "modules/Form";
import { useLodgeContext } from "hooks/useLodge";
import { useNavigate } from "react-router-dom";

const FiltersMain = ({ titleShowed = false, isShownStart = false }) => {
  const navigate = useNavigate();
  const [isShown, setIsShown] = useState(isShownStart);
  // const { payload, loading } = useDictionary();
  const { setFilters, filtersData } = useLodgeContext();
  const [formVersion, setFormVersion] = useState(1);

  useEffect(() => {
    setFormVersion(formVersion + 1);
  }, [filtersData]);

  const searchHandler = data => {
    setFilters({ ...filtersData, ...data });
    navigate("/noclegi");
  };

  const initialFilters = {
    city: "",
    min_price: "",
    adult: "",
    children: "",
    facilities: [],
    type: [],
    address: "",
    min_date: "",
    max_date: "",
    people_count: "",
    max_price: "",
  };

  // if (loading) return <div>LOADING</div>;

  // const facilitiesDictionary = payload.data.filter(item => item.type === "FACILITIES");
  // const objectTypeDictionary = payload.data.filter(item => item.type === "OBJECT_TYPE");

  return (
    <FormProvider config={filterConfig} payload={filtersData} key={formVersion}>
      <div className='col-12'>
        <div className='filters-main'>
          <div className='container'>
            <Card>
              {titleShowed && (
                <div className='title'>
                  <h3>Znajdź idealną ofertę na urlop!</h3>
                </div>
              )}
              <div className='row filters filters-main-module'>
                <div className='col-12 filters-group '>
                  <div className='row'>
                    <div className='col-12 col-md-6 col-lg-4'>
                      <Input id='city' placeholder={"Miejscowość"} />
                    </div>
                    <div className='col-12 col-md-6 col-lg-4'>
                      <Input id='address' placeholder={"Ulica"} />
                    </div>
                    <div className='col-6 col-md-4 col-lg-2'>
                      <Input id={"people_count"} placeholder='Ilość osób' />
                    </div>
                    <div className='col-6 col-md-8 col-lg-2 filter-btn'>
                      <ActionButton
                        onClick={data => searchHandler(data)}
                        className='primary'
                      >
                        Filtruj
                      </ActionButton>
                    </div>
                  </div>
                </div>
                {/* TEMPORARY COMMENTED */}
                {/* <div onClick={() => setIsShown(!isShown)} className='show-more col-12'>
                  {!isShown && <FontAwesomeIcon icon={faChevronDown} />}
                  {isShown && <FontAwesomeIcon icon={faChevronUp} />}
                  <span>Dodatkowe opcje filtrowania</span>
                </div>
                {isShown && (
                  <div className='col-12 filters-group'>
                    <div className='row'>
                      <div className='col-12 col-md-6'>
                        <label>Rodzaj obiektu</label>

                        <Dropdown dictionary={objectTypeDictionary} id='type' />
                      </div>
                      <div className='col-12 col-md-6'>
                        <label>Udogodnienia</label>
                        <MultiSelect
                          id='facilities'
                          dictionary={facilitiesDictionary}
                          type='dropdown'
                        />
                      </div>
                    </div>
                  </div>
                )} */}
                <div className='col-12 clear-form'>
                  <div className='col-3'>
                    <button
                      type='clear'
                      className='outline'
                      onClick={() => {
                        searchHandler(initialFilters);
                      }}
                    >
                      <FontAwesomeIcon icon={faXmark} /> Wyczyść
                    </button>
                  </div>
                </div>
              </div>
            </Card>
          </div>
        </div>
      </div>
    </FormProvider>
  );
};

export default FiltersMain;
